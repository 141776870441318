// File: context/UserContext.jsx
import React, { createContext, useState, useEffect, useContext } from "react";
import { getAllUsers } from "../utills/utills";

const UserContext = createContext();

export const UserContextProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [userList, setUserList] = useState([]);

  useEffect(() => {
    const token = localStorage.getItem("userToken");
    if (token) {
      const userData = JSON.parse(token);
      setUser(userData);
    }
  }, []);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await getAllUsers();
        setUserList(response);
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    };
    fetchUsers();
  }, []);

  const login = (user) => {
    localStorage.setItem("userToken", JSON.stringify(user));
    setUser(user);
  };

  const logout = () => {
    localStorage.removeItem("userToken");
    setUser(null);
  };


  return (
    <UserContext.Provider
      value={{
        user,
        setUser,
        login,
        logout,
        userList,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export const useAuth = () => useContext(UserContext);
