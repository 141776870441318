import React, { useState } from "react";
import { styled } from "@mui/system";
import {
  Button,
  Typography,
  Paper,
  RadioGroup,
  FormControlLabel,
  Radio,
  TextField,
  Box,
  Input,
  CircularProgress,
} from "@mui/material";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import ReceiptIcon from "@mui/icons-material/Receipt";
import { useCartContext } from "../../context/cartContext";
import { useNavigate } from "react-router-dom";
import { completePurchase } from "../../utills/utills";
import { useAuth } from "../../context/UserContext";
import { useSnackbar } from "notistack";

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  inlineSize: "100%",
  margin: "auto",
  borderRadius: theme.shape.borderRadius,
  [theme.breakpoints.up("md")]: {
    padding: theme.spacing(6),
  },
}));

const StyledHeader = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(3),
  fontWeight: "bold",
  fontSize: "2rem",
}));

const TotalSection = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-between",
  padding: theme.spacing(2),
  borderRadius: theme.shape.borderRadius,
  backgroundColor: theme.palette.grey[200],
  insetBlockStart: theme.spacing(4),
  inlineSize: "100%",
  [theme.breakpoints.up("md")]: {
    inlineSize: "50%",
  },
}));

const PaymentButton = styled(Button)(({ theme }) => ({
  padding: theme.spacing(2),
  insetBlockStart: theme.spacing(3),
  inlineSize: "100%",
  [theme.breakpoints.up("md")]: {
    inlineSize: "50%",
  },
  [theme.breakpoints.up("lg")]: {
    inlineSize: "25%",
  },
  [theme.breakpoints.down("sm")]: {
    inlineSize: "100%",
  },
}));

const PaymentPage = () => {
  const { total, cart } = useCartContext();
  const { user } = useAuth();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [paymentMethod, setPaymentMethod] = useState("credit-card");
  const [cardDetails, setCardDetails] = useState({
    cardNumber: "",
    expiryDate: "",
    cvv: "",
  });
  const [receiptImage, setReceiptImage] = useState(null);
  const [otp, setOtp] = useState("");
  const [otpStep, setOtpStep] = useState(false);
  const [loading, setLoading] = useState(false);

  const handlePayment = async () => {};

  const handleVerifyOtp = async () => {};

  const handleCompletePurchase = async () => {
    const productIds = cart.map((item) => item.product_id);
    try {
      await completePurchase(user.user_id, productIds, receiptImage, "pending");
      enqueueSnackbar("Purchase completed successfully", {
        variant: "success",
      });
      window.location.reload();
      navigate("/shopping");
    } catch (error) {
      enqueueSnackbar("Purchase failed", { variant: "error" });
      console.error("Error completing purchase:", error);
    }
  };

  const handleImageChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      setReceiptImage(e.target.files[0]);
    }
  };

  const handleBack = () => {
    navigate("/cart");
  };

  return (
    <StyledPaper>
      <StyledHeader variant="h4" component="h1">
        Payment
      </StyledHeader>
      <RadioGroup
        value={paymentMethod}
        onChange={(e) => setPaymentMethod(e.target.value)}
      >
        <FormControlLabel
          value="credit-card"
          control={<Radio />}
          label={
            <>
              <CreditCardIcon
                sx={{ verticalAlign: "middle", marginRight: 1 }}
              />
              Credit Card
            </>
          }
        />
      </RadioGroup>
      {paymentMethod === "credit-card" && !otpStep && (
        <Box>
          <TextField
            label="Card Number"
            variant="outlined"
            fullWidth
            margin="normal"
            value={cardDetails.cardNumber}
            onChange={(e) =>
              setCardDetails({ ...cardDetails, cardNumber: e.target.value })
            }
          />
          <TextField
            label="Expiry Date (MM/YY)"
            variant="outlined"
            fullWidth
            margin="normal"
            value={cardDetails.expiryDate}
            onChange={(e) =>
              setCardDetails({ ...cardDetails, expiryDate: e.target.value })
            }
          />
          <TextField
            label="CVV"
            variant="outlined"
            fullWidth
            margin="normal"
            value={cardDetails.cvv}
            onChange={(e) =>
              setCardDetails({ ...cardDetails, cvv: e.target.value })
            }
          />
        </Box>
      )}
      {otpStep && (
        <Box>
          <TextField
            label="Enter OTP"
            variant="outlined"
            fullWidth
            margin="normal"
            value={otp}
            onChange={(e) => setOtp(e.target.value)}
          />
          <PaymentButton
            variant="contained"
            color="primary"
            onClick={handleVerifyOtp}
            disabled={!otp}
          >
            Verify OTP
          </PaymentButton>
        </Box>
      )}

      <TotalSection>
        <Typography variant="h6" color="primary">
          Total Price:
        </Typography>
        <Typography variant="h6" color="primary">
          ${total}
        </Typography>
      </TotalSection>
      {!otpStep && (
        <PaymentButton
          variant="contained"
          color="primary"
          onClick={handlePayment}
          disabled={!paymentMethod || loading}
        >
          {loading ? <CircularProgress size={24} /> : "Proceed with Payment"}
        </PaymentButton>
      )}
      <div className="flex mt-5 justify-evenly flex-col">
        <Input
          id="upload-image"
          type="file"
          inputProps={{ accept: "image/*" }}
          style={{ display: "none" }}
          onChange={handleImageChange}
        />
        <label htmlFor="upload-image">
          <PaymentButton
            variant="contained"
            sx={{ backgroundColor: "black" }}
            component="span"
            disabled={!paymentMethod}
            startIcon={<ReceiptIcon />}
          >
            Upload Receipt
          </PaymentButton>
        </label>
        <PaymentButton
          variant="contained"
          color="secondary"
          onClick={handleCompletePurchase}
          disabled={!receiptImage || otpStep}
          sx={{ marginTop: 2, marginBottom: 4 }}
        >
          Complete Purchase
        </PaymentButton>
        <PaymentButton
          variant="outlined"
          color="secondary"
          onClick={handleBack}
        >
          Back to Cart
        </PaymentButton>
      </div>
    </StyledPaper>
  );
};

export default PaymentPage;
