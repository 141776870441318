import React, { useState } from "react";
import { TextField, Button, CircularProgress, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import { requestOTP, verifyOTP } from "../../utills/utills";

const ForgotPassword = ({ onCancel, onSwitch }) => {
  const [forgotPasswordStep, setForgotPasswordStep] = useState(1);
  const [email, setEmail] = useState("");
  const [otp, setOtp] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const resetForm = () => {
    setEmail("");
    setOtp("");
    setPassword("");
    setConfirmPassword("");
  };

  const handleRequestOTP = async () => {
    setLoading(true);
    try {
      await requestOTP(email);
      enqueueSnackbar("OTP sent to your email!", { variant: "success" });
      setForgotPasswordStep(2);
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
    } finally {
      setLoading(false);
    }
  };

  const handleVerifyOTP = async () => {
    if (password !== confirmPassword) {
      enqueueSnackbar("Passwords do not match!", { variant: "error" });
      return;
    }
    setLoading(true);
    try {
      await verifyOTP(email, otp, password);
      enqueueSnackbar("Password reset successful!", { variant: "success" });
      resetForm(); // Reset the form fields after successful password reset
      onSwitch();
      window.location.replace("/loginsignup");
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <Typography variant="h4" className="mb-6 text-center">
        Forgot Password
      </Typography>
      <form>
        {forgotPasswordStep === 1 && (
          <TextField
            label="Email"
            variant="outlined"
            fullWidth
            margin="normal"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="mb-4"
          />
        )}
        {forgotPasswordStep === 2 && (
          <>
            <TextField
              label="OTP"
              variant="outlined"
              fullWidth
              margin="normal"
              value={otp}
              onChange={(e) => setOtp(e.target.value)}
              className="mb-4"
            />
            <TextField
              label="New Password"
              type="password"
              variant="outlined"
              fullWidth
              margin="normal"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="mb-4"
            />
            <TextField
              label="Confirm New Password"
              type="password"
              variant="outlined"
              fullWidth
              margin="normal"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              className="mb-4"
            />
          </>
        )}
        {loading ? (
          <CircularProgress className="mt-4" />
        ) : (
          <>
            {forgotPasswordStep === 1 ? (
              <Button
                variant="contained"
                color="primary"
                fullWidth
                onClick={handleRequestOTP}
                className="mt-4"
              >
                Send OTP
              </Button>
            ) : (
              <Button
                variant="contained"
                color="primary"
                fullWidth
                onClick={handleVerifyOTP}
                className="mt-4"
              >
                Change Password
              </Button>
            )}
          </>
        )}
      </form>
      <Button onClick={onCancel} className="mt-4 w-full text-center">
        Cancel
      </Button>
    </div>
  );
};

export default ForgotPassword;
