import React, { createContext, useContext, useState, useEffect } from "react";
import { getCartProducts, getPurchases } from "../utills/utills";
import { useAuth } from "./UserContext";
import { useItemContext } from "./ItemsContext";

const CartContext = createContext();

export const useCartContext = () => {
  return useContext(CartContext);
};

export const CartProvider = ({ children }) => {
  const { user } = useAuth();
  const { allProducts } = useItemContext();
  const [cart, setCart] = useState([]);
  const [total, setTotal] = useState(0);
  const [orders, setOrders] = useState([]);
  const [pendingOrdersCount, setPendingOrdersCount] = useState(0);

  // Update total price
  const updateTotal = (cartItems) => {
    const sum = cartItems.reduce(
      (acc, item) => acc + parseFloat(item.price),
      0
    );
    setTotal(sum.toFixed(2));
  };

  useEffect(() => {
    const fetchCartItems = async () => {
      try {
        const cartItems = await getCartProducts(user.user_id);
        const filteredProducts = allProducts
          .filter((product) =>
            cartItems.some((item) => item.product_id === product.product_id)
          )
          .map((product) => {
            const cartItem = cartItems.find(
              (item) => item.product_id === product.product_id
            );
            return {
              ...product,
              cart_id: cartItem.cart_id,
              price: cartItem.price,
              package_start_date: cartItem.package_start_date,
              package_expire_date: cartItem.package_expire_date,
            };
          });
        setCart(filteredProducts);
        updateTotal(filteredProducts);
      } catch (error) {
        console.error("Error fetching items:", error);
      }
    };

    if (user) {
      fetchCartItems();
    }
  }, [user, allProducts]);

  // Fetch all purchases/orders
  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const response = await getPurchases();
        setOrders(response);
      } catch (error) {
        console.error("Error fetching orders:", error);
      }
    };
    fetchOrders();
  }, [user]);

  useEffect(() => {
    const pendingOrders = orders.filter((order) => order.status === "pending");
    setPendingOrdersCount(pendingOrders.length);
  }, [orders]);

  return (
    <CartContext.Provider
      value={{
        cart,
        total,
        orders,
        setCart,
        pendingOrdersCount,
      }}
    >
      {children}
    </CartContext.Provider>
  );
};
