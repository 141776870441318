import React, { useState, useEffect } from "react";
import {
  Card,
  CardContent,
  Typography,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableSortLabel,
  TablePagination,
  TextField,
  Button,
  IconButton,
} from "@mui/material";
import { CheckCircle, Cancel, ExitToApp } from "@mui/icons-material";
import { saveAs } from "file-saver";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { useCartContext } from "../../../context/cartContext";
import { acceptPurchase, rejectPurchase } from "../../../utills/utills";
import { useAuth } from "../../../context/UserContext";
import { useItemContext } from "../../../context/ItemsContext";
import { Link } from "react-router-dom";
import { useSnackbar } from "notistack";

const Orders = () => {
  const { orders } = useCartContext();
  const { userList } = useAuth();
  const { allProducts } = useItemContext();
  const { enqueueSnackbar } = useSnackbar();

  const [orderState, setOrderState] = useState({
    pendingOrders: [],
    ongoingOrders: [],
    completedOrders: [],
    rejectedOrders: [],
    page: 0,
    rowsPerPage: 5,
    order: "asc",
    orderBy: "",
    search: "",
  });

  const [userEmails, setUserEmails] = useState({});
  const [productNames, setProductNames] = useState({});

  useEffect(() => {
    const pendingOrders = orders.filter((order) => order.status === "pending");
    const ongoingOrders = orders.filter(
      (order) =>
        order.status === "accepted" && new Date(order.expire_date) > new Date()
    );
    const completedOrders = orders.filter(
      (order) =>
        order.status === "accepted" && new Date(order.expire_date) <= new Date()
    );
    const rejectedOrders = orders.filter(
      (order) => order.status === "rejected"
    );

    setOrderState((prevState) => ({
      ...prevState,
      pendingOrders,
      ongoingOrders,
      completedOrders,
      rejectedOrders,
    }));

    const fetchUserEmails = () => {
      const emails = {};
      orders.forEach((order) => {
        const user = userList.find((u) => u.id === order.user_id);
        if (user) {
          emails[order.user_id] = user.email;
        }
      });
      setUserEmails(emails);
    };

    const fetchProductNames = () => {
      const names = {};
      orders.forEach((order) => {
        const product = allProducts.find((p) => p.id === order.product_id);
        if (product) {
          names[order.product_id] = product.name;
        }
      });
      setProductNames(names);
    };

    fetchUserEmails();
    fetchProductNames();
  }, [orders, userList, allProducts]);

  const handleSort = (property) => {
    const isAsc = orderState.orderBy === property && orderState.order === "asc";
    setOrderState({
      ...orderState,
      order: isAsc ? "desc" : "asc",
      orderBy: property,
    });
  };

  const handleSearch = (event) => {
    setOrderState({ ...orderState, search: event.target.value });
  };

  const handleChangePage = (event, newPage) => {
    setOrderState({ ...orderState, page: newPage });
  };

  const handleChangeRowsPerPage = (event) => {
    setOrderState({
      ...orderState,
      rowsPerPage: parseInt(event.target.value, 10),
      page: 0,
    });
  };

  const exportToCSV = (data, fileName) => {
    const headers = [
      "Order ID",
      "Product ID",
      "Order Date",
      "Order Finish Date",
      "User ID",
      "Payment Receipt",
    ];
    const csvData = data
      .map((row) =>
        [
          row.purchase_id,
          productNames[row.product_id] || "Unknown Product",
          row.purchase_date,
          row.expire_date,
          userEmails[row.user_id] || "Unknown User",
          row.receipt_image,
        ]
          .map((field) => `"${field || ""}"`)
          .join(",")
      )
      .join("\n");
    saveAs(
      new Blob([headers.join(",") + "\n" + csvData], { type: "text/csv" }),
      fileName
    );
  };

  const exportToPDF = (data, fileName) => {
    const doc = new jsPDF();
    const headers = [
      "Order ID",
      "Product ID",
      "Order Date",
      "Order Finish Date",
      "User ID",
      "Payment Receipt",
    ];
    const rows = data.map((row) =>
      [
        row.purchase_id,
        productNames[row.product_id] || row.product_id,
        row.purchase_date,
        row.expire_date,
        userEmails[row.user_id] || row.user_id,
        row.receipt_image,
      ].map((field) => field || "")
    );
    doc.autoTable({
      head: [headers],
      body: rows,
    });
    doc.save(fileName);
  };

  const handleAccept = async (purchaseId) => {
    try {
      await acceptPurchase(purchaseId);
      enqueueSnackbar("Order Accepted", { variant: "success" });
      window.location.reload();
    } catch (error) {
      enqueueSnackbar("Error Accepting Order", { variant: "warning" });
    }
  };

  const handleReject = async (purchaseId) => {
    try {
      await rejectPurchase(purchaseId);
      enqueueSnackbar("Order Rejected", { variant: "info" });
      window.location.reload();
    } catch (error) {
      enqueueSnackbar("Error Rejecting Order", { variant: "warning" });
    }
  };

  const handleEndOrder = async (purchaseId) => {
    try {
      await rejectPurchase(purchaseId);
      enqueueSnackbar("Order Ended", { variant: "success" });
      window.location.reload();
    } catch (error) {
      enqueueSnackbar("Error Ending Order", { variant: "warning" });
    }
  };

  const filteredData = (data) =>
    data
      .map((row) => ({
        ...row,
        product_name: productNames[row.product_id] || row.product_id,
        user_email: userEmails[row.user_id] || row.user_id,
      }))
      .filter((row) =>
        [
          "purchase_id",
          "product_name",
          "purchase_date",
          "expire_date",
          "user_email",
          "receipt_image",
        ].some((key) =>
          (row[key] || "")
            .toString()
            .toLowerCase()
            .includes(orderState.search.toLowerCase())
        )
      )
      .sort((a, b) => {
        const isAsc = orderState.order === "asc";
        if ((a[orderState.orderBy] || "") < (b[orderState.orderBy] || "")) {
          return isAsc ? -1 : 1;
        }
        if ((a[orderState.orderBy] || "") > (b[orderState.orderBy] || "")) {
          return isAsc ? 1 : -1;
        }
        return 0;
      })
      .slice(
        orderState.page * orderState.rowsPerPage,
        orderState.page * orderState.rowsPerPage + orderState.rowsPerPage
      );

  return (
    <div className="p-4 w-full">
      {[
        "pendingOrders",
        "ongoingOrders",
        "completedOrders",
        "rejectedOrders",
      ].map((status) => (
        <Card className="mb-4" key={status}>
          <CardContent>
            <Typography
              variant="h5"
              component="h2"
              className="text-center mb-4"
            >
              {status.replace(/([A-Z])/g, " $1").toUpperCase()}
            </Typography>
            <TextField
              label="Search"
              variant="outlined"
              size="small"
              value={orderState.search}
              onChange={handleSearch}
              className="mb-4 w-full"
            />
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <TableSortLabel
                      active={orderState.orderBy === "purchase_id"}
                      direction={
                        orderState.orderBy === "purchase_id"
                          ? orderState.order
                          : "asc"
                      }
                      onClick={() => handleSort("purchase_id")}
                    >
                      Order ID
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>
                    <TableSortLabel
                      active={orderState.orderBy === "product_name"}
                      direction={
                        orderState.orderBy === "product_name"
                          ? orderState.order
                          : "asc"
                      }
                      onClick={() => handleSort("product_name")}
                    >
                      Product ID
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>
                    <TableSortLabel
                      active={orderState.orderBy === "purchase_date"}
                      direction={
                        orderState.orderBy === "purchase_date"
                          ? orderState.order
                          : "asc"
                      }
                      onClick={() => handleSort("purchase_date")}
                    >
                      Order Date
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>
                    <TableSortLabel
                      active={orderState.orderBy === "expire_date"}
                      direction={
                        orderState.orderBy === "expire_date"
                          ? orderState.order
                          : "asc"
                      }
                      onClick={() => handleSort("expire_date")}
                    >
                      Order Finish Date
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>
                    <TableSortLabel
                      active={orderState.orderBy === "user_email"}
                      direction={
                        orderState.orderBy === "user_email"
                          ? orderState.order
                          : "asc"
                      }
                      onClick={() => handleSort("user_email")}
                    >
                      User ID
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>
                    <TableSortLabel
                      active={orderState.orderBy === "receipt_image"}
                      direction={
                        orderState.orderBy === "receipt_image"
                          ? orderState.order
                          : "asc"
                      }
                      onClick={() => handleSort("receipt_image")}
                    >
                      Payment Receipt
                    </TableSortLabel>
                  </TableCell>
                  {status === "pendingOrders" && (
                    <>
                      <TableCell>Accept Order</TableCell>
                      <TableCell>Reject Order</TableCell>
                    </>
                  )}
                  {status === "ongoingOrders" && (
                    <TableCell>End Order</TableCell>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredData(orderState[status]).map((row) => (
                  <TableRow key={row.purchase_id}>
                    <TableCell>{row.purchase_id}</TableCell>
                    <TableCell>
                      <Link
                        to={`/shopping/${row.product_id}`}
                        className="text-blue-500"
                      >
                        {row.product_name}
                      </Link>
                    </TableCell>
                    <TableCell>{row.purchase_date}</TableCell>
                    <TableCell>{row.expire_date}</TableCell>
                    <TableCell>
                      <Link
                        to={`/users/profile/${row.user_id}`}
                        className="text-blue-500"
                      >
                        {row.user_email}
                      </Link>
                    </TableCell>
                    <TableCell>
                      <a
                        href={
                          row.receipt_image.startsWith("http://") ||
                          row.receipt_image.startsWith("https://")
                            ? row.receipt_image
                            : `http://${row.receipt_image}`
                        }
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-blue-500"
                      >
                        View Receipt
                      </a>
                    </TableCell>
                    {status === "pendingOrders" && (
                      <>
                        <TableCell>
                          <IconButton
                            color="primary"
                            onClick={() => handleAccept(row.purchase_id)}
                          >
                            <CheckCircle />
                          </IconButton>
                        </TableCell>
                        <TableCell>
                          <IconButton
                            color="secondary"
                            onClick={() => handleReject(row.purchase_id)}
                          >
                            <Cancel />
                          </IconButton>
                        </TableCell>
                      </>
                    )}
                    {status === "ongoingOrders" && (
                      <TableCell>
                        <IconButton
                          color="default"
                          onClick={() => handleEndOrder(row.purchase_id)}
                        >
                          <ExitToApp />
                        </IconButton>
                      </TableCell>
                    )}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={orderState[status].length}
              rowsPerPage={orderState.rowsPerPage}
              page={orderState.page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
            <div className="flex space-x-2 mt-4">
              <Button
                variant="contained"
                color="primary"
                onClick={() => exportToCSV(orderState[status], `${status}.csv`)}
              >
                Export to CSV
              </Button>
              <Button
                variant="contained"
                color="secondary"
                onClick={() => exportToPDF(orderState[status], `${status}.pdf`)}
              >
                Export to PDF
              </Button>
            </div>
          </CardContent>
        </Card>
      ))}
    </div>
  );
};

export default Orders;
