import React, { useState } from "react";
import {
  Typography,
  Container,
  Card,
  CardContent,
  Grid,
  Box,
  Button,
  TextField,
} from "@mui/material";
import { styled } from "@mui/system";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import EmailIcon from "@mui/icons-material/Email";
import PhoneIcon from "@mui/icons-material/Phone";
import SecurityIcon from "@mui/icons-material/Security";
import NoAccountsIcon from "@mui/icons-material/NoAccounts";
import EditIcon from "@mui/icons-material/Edit";
import { useAuth } from "../../context/UserContext";
import { sendEmailToAdmin } from "../../utills/utills";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";

const ProfileContainer = styled(Container)(({ theme }) => ({
  padding: theme.spacing(4),
  minHeight: "100vh",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
}));

const ProfileCard = styled(Card)(({ theme }) => ({
  width: "100%",
  marginTop: theme.spacing(2),
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[3],
}));

const ContactCard = styled(Card)(({ theme }) => ({
  width: "100%",
  marginTop: theme.spacing(4),
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[3],
}));

const StyledButton = styled(Button)(({ theme }) => ({
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    marginTop: theme.spacing(2),
  },
}));

const Profile = () => {
  const { user } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [message, setMessage] = useState("");
  const [subject, setSubject] = useState("");

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!message || !subject) return;

    try {
      await sendEmailToAdmin(user.email, subject, message);
      enqueueSnackbar("Message sent successfully!", { variant: "success" });
      setMessage("");
      setSubject("");
    } catch (error) {
      enqueueSnackbar("Failed to send message.", { variant: "error" });
    }
  };

  if (!user) {
    return (
      <Container
        sx={{
          display: "flex",
          minHeight: "100vh",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <NoAccountsIcon
          fontSize="large"
          sx={{ color: "gray", marginRight: 2 }}
        />
        <Typography variant="h6" align="center">
          No user is logged in
        </Typography>
      </Container>
    );
  }

  return (
    <ProfileContainer maxWidth="lg">
      <div className="w-full flex items-center justify-between text-2xl font-semibold mb-2">
        <h1 className="text-4xl font-semibold">Profile</h1>
      </div>
      <ProfileCard>
        <CardContent>
          <Grid container spacing={3} alignItems="start">
            <Grid item xs={12} sm={6} display="flex" alignItems="center">
              <AccountCircleIcon
                fontSize="large"
                sx={{ color: "blue", marginRight: 2 }}
              />
              <Typography variant="h4">{user.username}</Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              display="flex"
              justifyContent={{ xs: "center", sm: "flex-end" }}
            >
              <StyledButton
                variant="contained"
                color="primary"
                startIcon={<EditIcon />}
                onClick={() => navigate(`/users/edit/${user.user_id}`)}
              >
                Edit Profile
              </StyledButton>
            </Grid>
            <Grid item xs={12} display="flex" alignItems="center">
              <AccountCircleIcon sx={{ color: "blue", marginRight: 2 }} />
              <Typography variant="body1">
                <strong>Full Name:</strong>
              </Typography>
              <Box sx={{ flexGrow: 1, textAlign: "right" }}>
                {user.username}
              </Box>
            </Grid>
            <Grid item xs={12} display="flex" alignItems="center">
              <EmailIcon sx={{ color: "blue", marginRight: 2 }} />
              <Typography variant="body1">
                <strong>Email:</strong>
              </Typography>
              <Box sx={{ flexGrow: 1, textAlign: "right" }}>{user.email}</Box>
            </Grid>
            <Grid item xs={12} display="flex" alignItems="center">
              <SecurityIcon sx={{ color: "blue", marginRight: 2 }} />
              <Typography variant="body1">
                <strong>Role:</strong>
              </Typography>
              <Box sx={{ flexGrow: 1, textAlign: "right" }}>
                {user.user_role}
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <Box display="flex" alignItems="center">
                <PhoneIcon sx={{ color: "blue", marginRight: 2 }} />
                <Typography variant="body1">
                  <strong>Phone:</strong>
                </Typography>
              </Box>
              <Typography variant="body1">{user.mobile || "N/A"}</Typography>
            </Grid>
          </Grid>
        </CardContent>
      </ProfileCard>
      <ContactCard>
        <CardContent>
          <Typography variant="h6" gutterBottom>
            Contact Admin
          </Typography>
          <form onSubmit={handleSubmit}>
            <TextField
              fullWidth
              label="Subject"
              variant="outlined"
              value={subject}
              onChange={(e) => setSubject(e.target.value)}
              sx={{ marginBottom: 3 }}
            />
            <TextField
              fullWidth
              label="Message"
              variant="outlined"
              multiline
              rows={4}
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              sx={{ marginBottom: 3 }}
            />
            <Button type="submit" variant="contained" color="primary" fullWidth>
              Send Message
            </Button>
          </form>
        </CardContent>
      </ContactCard>
    </ProfileContainer>
  );
};

export default Profile;
