// src/components/Cart/Cart.js

import React from "react";
import { styled } from "@mui/system";
import { Button, Typography, Paper, Box } from "@mui/material";
import CartItem from "./CartItem";
import { useCartContext } from "../../context/cartContext";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import { removeProductFromCart } from "../../utills/utills";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  width: "100%",
  margin: "auto",
  borderRadius: theme.shape.borderRadius,
  [theme.breakpoints.up("md")]: {
    padding: theme.spacing(6),
  },
}));



const TotalSection = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-between",
  padding: theme.spacing(2),
  borderRadius: theme.shape.borderRadius,
  backgroundColor: theme.palette.grey[200],
  marginTop: theme.spacing(4),
  width: "100%",
  [theme.breakpoints.up("md")]: {
    width: "50%",
  },
}));

const PaymentButton = styled(Button)(({ theme }) => ({
  padding: theme.spacing(2),
  marginTop: theme.spacing(3),
  width: "100%",
  [theme.breakpoints.up("md")]: {
    width: "50%",
  },
  [theme.breakpoints.up("lg")]: {
    width: "25%",
  },
}));

const EmptyCartContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  padding: theme.spacing(3),
  textAlign: "center",
  color: theme.palette.text.secondary,
  height: "100%",
  [theme.breakpoints.up("md")]: {
    padding: theme.spacing(6),
  },
}));

const EmptyCartIcon = styled(ShoppingCartOutlinedIcon)(({ theme }) => ({
  fontSize: "4rem",
  marginBottom: theme.spacing(2),
  color: theme.palette.text.secondary,
}));

const Cart = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { cart, total, setCart } = useCartContext();
  const navigate = useNavigate();

  const handleRemove = async (cart_id) => {
    try {
      await removeProductFromCart(cart_id);
      const newCart = cart.filter((item) => item.cart_id !== cart_id);
      enqueueSnackbar("Item removed successfully", { variant: "success" });
      setCart(newCart);
    } catch (error) {
      enqueueSnackbar("Error removing product from cart", { variant: "error" });
    }
  };

  const handlePayment = async () => {
    const availableProducts = cart.filter((item) => item.availability);

    if (availableProducts.length === 0) {
      enqueueSnackbar("No available products in the cart", {
        variant: "error",
      });
      return;
    }

    const unavailableProducts = cart.filter((item) => !item.availability);
    for (const product of unavailableProducts) {
      await handleRemove(product.cart_id);
    }

    setCart(availableProducts);
    enqueueSnackbar("Unavailable products removed from cart", {
      variant: "info",
    });
    navigate("/payment");
  };

  const handleShopMore = () => {
    navigate("/shopping");
  };

  if (cart ? cart.length === 0 : true) {
    return (
      <EmptyCartContainer>
        <EmptyCartIcon />
        <Typography variant="h5">Your cart is empty</Typography>
        <Typography variant="body1" sx={{ marginTop: "8px" }}>
          Looks like you haven't added anything to your cart yet.
        </Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={handleShopMore}
          sx={{ marginTop: "24px" }}
        >
          Shop Now
        </Button>
      </EmptyCartContainer>
    );
  }

  return (
    <StyledPaper>
      <div className="w-full flex items-center justify-between text-2xl font-semibold mb-2">
        <h1 className="text-4xl font-semibold">Shopping Cart</h1>
      </div>
      {cart.map((item) => (
        <CartItem
          key={item.product_id}
          product={item}
          onRemove={() => handleRemove(item.cart_id)}
        />
      ))}
      <TotalSection>
        <Typography variant="h6" color="primary">
          Total Price:
        </Typography>
        <Typography variant="h6" color="primary">
          ${total}
        </Typography>
      </TotalSection>
      <PaymentButton
        variant="contained"
        color="primary"
        onClick={handlePayment}
      >
        Proceed to Payment
      </PaymentButton>
    </StyledPaper>
  );
};

export default Cart;
