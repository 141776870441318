import React, { useState, useEffect } from "react";
import { useAuth } from "../../../context/UserContext";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import Pagination from "@mui/material/Pagination";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import SearchIcon from "@mui/icons-material/Search";
import PersonIcon from "@mui/icons-material/Person";
import FingerprintIcon from "@mui/icons-material/Fingerprint";
import EmailIcon from "@mui/icons-material/Email";
import { deleteUser } from "../../../utills/utills";
import { useSnackbar } from "notistack";

const Users = () => {
  const { userList } = useAuth();
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage] = useState(50);
  const [openDialog, setOpenDialog] = useState(false);
  const [deleteTarget, setDeleteTarget] = useState(null);
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (userList) {
      setFilteredUsers(userList);
    }
  }, [userList]);

  useEffect(() => {
    if (userList) {
      const filtered = userList.filter(
        (user) =>
          user.username.toLowerCase().includes(searchTerm.toLowerCase()) ||
          user.email.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredUsers(filtered);
    }
  }, [searchTerm, userList]);

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const handleOpenDialog = (user_id) => {
    setDeleteTarget(user_id);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setDeleteTarget(null);
  };

  const handleDelete = async () => {
    try {
      await deleteUser(deleteTarget);
      enqueueSnackbar("User deleted successfully", { variant: "success" });
      setFilteredUsers(
        filteredUsers.filter((user) => user.user_id !== deleteTarget)
      );
      setOpenDialog(false);
      setDeleteTarget(null);
      window.location.reload();
    } catch (error) {
      enqueueSnackbar("Error deleting user: " + error.message, {
        variant: "error",
      });
      console.error("Error deleting user:", error);
    }
  };

  const indexOfLastUser = currentPage * rowsPerPage;
  const indexOfFirstUser = indexOfLastUser - rowsPerPage;
  const currentUsers = filteredUsers.slice(indexOfFirstUser, indexOfLastUser);

  if (!userList) {
    return <>No Users</>;
  }

  return (
    <div className="w-full flex flex-col p-4">
      <div className="w-full flex items-center justify-between mb-6">
        <h1 className="text-4xl font-semibold">User Management</h1>
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={() => navigate("/create-user")}
        >
          Add User
        </Button>
      </div>
      <div className="w-full flex items-center justify-between mb-6">
        <TextField
          label="Search by name or email"
          variant="outlined"
          fullWidth
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          InputProps={{
            startAdornment: <SearchIcon />,
          }}
        />
      </div>
      <div className="w-full mt-10">
        <table className="w-full table-auto mb-4 border-collapse">
          <thead>
            <tr className="bg-gray-200">
              <th className="border p-2 text-start">Name</th>
              <th className="border p-2 text-start">Email</th>
              <th className="border p-2 text-start">Device</th>
              <th className="border p-2 text-start">Operations</th>
            </tr>
          </thead>
          <tbody>
            {currentUsers
              .filter((user) => user.user_role === "user")
              .map((user, index) => (
                <tr key={index} className="border-b">
                  <td
                    className="border p-2 text-blue-500 cursor-pointer"
                    onClick={() => navigate(`/users/profile/${user.user_id}`)}
                  >
                    <PersonIcon className="mr-2" />
                    {user.username}
                  </td>
                  <td className="border p-2">
                    <EmailIcon className="mr-2" />
                    {user.email}
                  </td>
                  <td className="border p-2">
                    <FingerprintIcon className="mr-2" />
                    {user.fingerprint || "N/A"}
                  </td>
                  <td className="border p-2">
                    <IconButton
                      color="primary"
                      onClick={() => navigate(`/users/edit/${user.user_id}`)}
                    >
                      <EditIcon />
                    </IconButton>
                    <IconButton
                      color="secondary"
                      onClick={() => handleOpenDialog(user.user_id)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
        <Pagination
          count={Math.ceil(filteredUsers.length / rowsPerPage)}
          page={currentPage}
          onChange={handlePageChange}
          className="mt-4"
        />
      </div>
      {/* Delete Confirmation Dialog */}
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Confirm Delete"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this user?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDelete} color="secondary" autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Users;
