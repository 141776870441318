import React, { useState, useEffect } from "react";
import { useItemContext } from "../../../context/ItemsContext";
import {
  Container,
  TextField,
  Pagination,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { deleteProduct } from "../../../utills/utills";
import { useSnackbar } from "notistack";

const Products = () => {
  const { allProducts } = useItemContext();
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = 10;
  const [sortConfig, setSortConfig] = useState({ key: null, direction: "asc" });
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogProductId, setDialogProductId] = useState(null);

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (!allProducts) {
      console.error(
        "allProducts is undefined. Please check your context provider."
      );
    }
  }, [allProducts]);

  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
  };

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const handleSort = (key) => {
    let direction = "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key, direction });
  };

  const handleImageClick = (itemId) => {
    // Handle image click logic here
  };

  const handleOpenDialog = (productId) => {
    setDialogProductId(productId);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setDialogProductId(null);
  };

  const handleDeleteProduct = async () => {
    try {
      await deleteProduct(dialogProductId);
      handleCloseDialog();
      window.location.reload();
      enqueueSnackbar("Product deleted successfully", { variant: "success" });
    } catch (error) {
      enqueueSnackbar("Failed to delete.", { variant: "error" });
    }
  };

  const sortedProducts = allProducts
    ? [...allProducts].sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === "asc" ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === "asc" ? 1 : -1;
        }
        return 0;
      })
    : [];

  const filteredProducts = sortedProducts.filter((product) =>
    product.product_name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const indexOfLastProduct = currentPage * rowsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - rowsPerPage;
  const currentProducts = filteredProducts.slice(
    indexOfFirstProduct,
    indexOfLastProduct
  );

  if (!allProducts) {
    return <Typography variant="h6">Loading products...</Typography>;
  }

  return (
    <Container sx={{ flexGrow: 1, padding: 4 }}>
      <div className="w-full flex items-center justify-between text-2xl font-semibold mb-6">
        <h1 className="text-4xl font-semibold">Products</h1>
      </div>
      <TextField
        label="Search Products"
        variant="outlined"
        fullWidth
        value={searchQuery}
        onChange={handleSearch}
        sx={{ marginBottom: 2 }}
      />
      <TableContainer component={Paper} elevation={3} className="shadow-md">
        <Table>
          <TableHead>
            <TableRow>
              <TableCell
                className="cursor-pointer"
                onClick={() => handleSort("product_image")}
              >
                Image
              </TableCell>
              <TableCell
                className="cursor-pointer"
                onClick={() => handleSort("product_name")}
              >
                Item Name
              </TableCell>
              <TableCell
                className="cursor-pointer"
                onClick={() => handleSort("start_date")}
              >
                Start Date
              </TableCell>
              <TableCell
                className="cursor-pointer"
                onClick={() => handleSort("finish_date")}
              >
                Expire Date
              </TableCell>
              <TableCell
                className="cursor-pointer"
                onClick={() => handleSort("availability")}
              >
                Available Status
              </TableCell>
              <TableCell
                className="cursor-pointer"
                onClick={() => handleSort("item_url")}
              >
                URL
              </TableCell>
              <TableCell>Operations</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {currentProducts.map((product) => (
              <TableRow key={product.product_id}>
                <TableCell>
                  <img
                    src={product.product_image}
                    alt={product.product_name}
                    className="w-12 h-12 cursor-pointer"
                    onClick={() => handleImageClick(product.product_id)}
                  />
                </TableCell>
                <TableCell>{product.product_name}</TableCell>
                <TableCell>
                  {product.start_date
                    ? new Date(product.start_date).toLocaleDateString()
                    : "N/A"}
                </TableCell>
                <TableCell>
                  {product.finish_date
                    ? new Date(product.finish_date).toLocaleDateString()
                    : "N/A"}
                </TableCell>
                <TableCell>
                  {product.availability ? "Available" : "Not Available"}
                </TableCell>
                <TableCell>
                  <a
                    href={product.item_url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {product.item_url}
                  </a>
                </TableCell>
                <TableCell>
                  <IconButton
                    aria-label="delete"
                    color="secondary"
                    onClick={() => handleOpenDialog(product.product_id)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Pagination
        count={Math.ceil(filteredProducts.length / rowsPerPage)}
        page={currentPage}
        onChange={handlePageChange}
        sx={{ marginTop: 4, display: "flex", justifyContent: "center" }}
      />
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Delete Product"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this product?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDeleteProduct} color="primary" autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default Products;
