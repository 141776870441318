import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import HomeIcon from "@mui/icons-material/Home";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import ShoppingBagIcon from "@mui/icons-material/ShoppingBag";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import LoginIcon from "@mui/icons-material/Login";
import LogoutIcon from "@mui/icons-material/Logout";
import PersonIcon from "@mui/icons-material/Person";
import Badge from "@mui/material/Badge";
import { Link, useLocation } from "react-router-dom";
import { useAuth } from "../context/UserContext";
import NavigationLayout from "../navigation/NavigationLayout";
import useMediaQuery from "@mui/material/useMediaQuery";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import CategoryIcon from "@mui/icons-material/Category";
import { useCartContext } from "../context/cartContext";
import { motion } from "framer-motion";
import { useState, useEffect } from "react";
import logo from "../assets/logo.avif";

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  backgroundColor: "black",
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export default function Sidebar() {
  const { logout, user } = useAuth();
  const { cart, pendingOrdersCount } = useCartContext();
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const location = useLocation();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const handleDrawerOpen = () => setOpen(true);
  const handleDrawerClose = () => setOpen(false);

  const handleWindowResize = () => setWindowWidth(window.innerWidth);

  useEffect(() => {
    window.addEventListener("resize", handleWindowResize);
    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar position="fixed" open={open && !isSmallScreen}>
        <Toolbar className="flex justify-between">
          {windowWidth < 600 ? (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <img
                src={logo}
                alt="logo"
                style={{ height: 40, marginRight: 16 }}
              />

              <IconButton component={Link} to="/">
                <HomeIcon sx={{ color: "white" }} />
              </IconButton>
              <IconButton component={Link} to="/profile">
                <AccountCircleIcon sx={{ color: "white" }} />
              </IconButton>
              <IconButton component={Link} to="/shopping">
                <ShoppingBagIcon sx={{ color: "white" }} />
              </IconButton>
              <IconButton component={Link} to="/cart">
                <Badge
                  badgeContent={cart ? cart.length : 0}
                  color="error"
                  sx={{
                    "& .MuiBadge-badge": {
                      right: 5,
                      top: 2,
                      border: `2px solid ${theme.palette.background.paper}`,
                      padding: "0 4px",
                    },
                  }}
                >
                  <ShoppingCartIcon sx={{ color: "white" }} />
                </Badge>
              </IconButton>
              {(user?.user_role === "admin" ||
                user?.user_role === "superadmin") && (
                <>
                  <IconButton component={Link} to="/orders">
                    <Badge
                      badgeContent={pendingOrdersCount ? pendingOrdersCount : 0}
                      color="error"
                      sx={{
                        "& .MuiBadge-badge": {
                          right: 5,
                          top: 2,
                          border: `2px solid ${theme.palette.background.paper}`,
                          padding: "0 4px",
                        },
                      }}
                    >
                      <AccountBalanceWalletIcon sx={{ color: "white" }} />
                    </Badge>
                  </IconButton>
                  <IconButton component={Link} to="/users">
                    <PersonIcon sx={{ color: "white" }} />
                  </IconButton>
                  <IconButton component={Link} to="/products">
                    <CategoryIcon sx={{ color: "white" }} />
                  </IconButton>
                </>
              )}
              <IconButton
                component={Link}
                to="/loginsignup"
                onClick={() => logout()}
              >
                {user ? (
                  <LogoutIcon sx={{ color: "white" }} />
                ) : (
                  <LoginIcon sx={{ color: "white" }} />
                )}
              </IconButton>
            </Box>
          ) : (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 1 }}
              className="left-4 text-white text-2xl font-bold"
            >
              Premium
            </motion.div>
          )}
        </Toolbar>
      </AppBar>
      {windowWidth >= 600 && (
        <Drawer
          variant="permanent"
          open={open}
          onMouseEnter={handleDrawerOpen}
          onMouseLeave={handleDrawerClose}
          sx={{
            display: isSmallScreen ? "block" : "block",
          }}
        >
          <DrawerHeader>
            <img
              src="https://img.freepik.com/premium-psd/premium-text-png-transparent-psd_873925-393170.jpg"
              alt="logo"
              style={{ height: 40, width: "100%" }}
            />
            {!open && !isSmallScreen && (
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 1 }}
                className="text-black text-2xl font-bold"
              >
                Premium
              </motion.div>
            )}
          </DrawerHeader>
          <Divider />
          <List>
            <ListItem disablePadding sx={{ display: "block" }}>
              <Link to="/">
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                    color: "black",
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : "auto",
                      justifyContent: "center",
                      color: "black",
                    }}
                  >
                    <HomeIcon sx={{ fontSize: 20 }} />
                  </ListItemIcon>
                  <ListItemText
                    primary="Dashboard"
                    sx={{ opacity: open ? 1 : 0 }}
                  />
                </ListItemButton>
              </Link>
              <Link to="/profile">
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                    color: "black",
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : "auto",
                      justifyContent: "center",
                      color: "black",
                    }}
                  >
                    <AccountCircleIcon sx={{ fontSize: 20 }} />
                  </ListItemIcon>
                  <ListItemText
                    primary="Profile"
                    sx={{ opacity: open ? 1 : 0 }}
                  />
                </ListItemButton>
              </Link>
              <Link to="/shopping">
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                    color: "black",
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : "auto",
                      justifyContent: "center",
                      color: "black",
                    }}
                  >
                    <ShoppingBagIcon sx={{ fontSize: 20 }} />
                  </ListItemIcon>
                  <ListItemText primary="Shop" sx={{ opacity: open ? 1 : 0 }} />
                </ListItemButton>
              </Link>
            </ListItem>
          </List>
          <List>
            <ListItem disablePadding sx={{ display: "block" }}>
              <Link to="/cart">
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                    color: "black",
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : "auto",
                      justifyContent: "center",
                      color: "black",
                    }}
                  >
                    <Badge
                      badgeContent={cart ? cart.length : 0}
                      color="error"
                      sx={{
                        "& .MuiBadge-badge": {
                          right: 5,
                          top: 2,
                          border: `2px solid ${theme.palette.background.paper}`,
                          padding: "0 4px",
                        },
                      }}
                    >
                      <ShoppingCartIcon sx={{ fontSize: 20 }} />
                    </Badge>
                  </ListItemIcon>
                  <ListItemText primary="Cart" sx={{ opacity: open ? 1 : 0 }} />
                </ListItemButton>
              </Link>
              <Divider />
              {(user?.user_role === "admin" ||
                user?.user_role === "superadmin") && (
                <>
                  <Link to="/orders">
                    <ListItemButton
                      sx={{
                        minHeight: 48,
                        justifyContent: open ? "initial" : "center",
                        px: 2.5,
                        color: "black",
                      }}
                    >
                      <ListItemIcon
                        sx={{
                          minWidth: 0,
                          mr: open ? 3 : "auto",
                          justifyContent: "center",
                          color: "black",
                        }}
                      >
                        <Badge
                          badgeContent={
                            pendingOrdersCount ? pendingOrdersCount : 0
                          }
                          color="error"
                          sx={{
                            "& .MuiBadge-badge": {
                              right: 5,
                              top: 2,
                              border: `2px solid ${theme.palette.background.paper}`,
                              padding: "0 4px",
                            },
                          }}
                        >
                          <AccountBalanceWalletIcon sx={{ fontSize: 20 }} />
                        </Badge>
                      </ListItemIcon>
                      <ListItemText
                        primary="Orders"
                        sx={{ opacity: open ? 1 : 0 }}
                      />
                    </ListItemButton>
                  </Link>
                  <Link to="/users">
                    <ListItemButton
                      sx={{
                        minHeight: 48,
                        justifyContent: open ? "initial" : "center",
                        px: 2.5,
                        color: "black",
                      }}
                    >
                      <ListItemIcon
                        sx={{
                          minWidth: 0,
                          mr: open ? 3 : "auto",
                          justifyContent: "center",
                          color: "black",
                        }}
                      >
                        <PersonIcon sx={{ fontSize: 20 }} />
                      </ListItemIcon>
                      <ListItemText
                        primary="Users"
                        sx={{ opacity: open ? 1 : 0 }}
                      />
                    </ListItemButton>
                  </Link>
                  <Link to="/products">
                    <ListItemButton
                      sx={{
                        minHeight: 48,
                        justifyContent: open ? "initial" : "center",
                        px: 2.5,
                        color: "black",
                      }}
                    >
                      <ListItemIcon
                        sx={{
                          minWidth: 0,
                          mr: open ? 3 : "auto",
                          justifyContent: "center",
                          color: "black",
                        }}
                      >
                        <CategoryIcon sx={{ fontSize: 20 }} />
                      </ListItemIcon>
                      <ListItemText
                        primary="Products"
                        sx={{ opacity: open ? 1 : 0 }}
                      />
                    </ListItemButton>
                  </Link>
                  <Divider />
                </>
              )}
            </ListItem>
            <Link to="/loginsignup">
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                  color: "black",
                }}
                onClick={() => logout()}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                    color: "black",
                  }}
                >
                  {user ? (
                    <LogoutIcon sx={{ fontSize: 20 }} />
                  ) : (
                    <LoginIcon sx={{ fontSize: 20 }} />
                  )}
                </ListItemIcon>
                {user ? (
                  <ListItemText sx={{ opacity: open ? 1 : 0 }}>
                    Logout
                  </ListItemText>
                ) : (
                  <ListItemText sx={{ opacity: open ? 1 : 0 }}>
                    Login
                  </ListItemText>
                )}
              </ListItemButton>
            </Link>
          </List>
        </Drawer>
      )}
      <Box component="main" sx={{ minHeight: "100vh", width: "100%" }}>
        <DrawerHeader />
        <NavigationLayout />
      </Box>
    </Box>
  );
}
