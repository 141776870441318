import React, { useState, useEffect } from "react";
import {
  TextField,
  Button,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Pagination,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Snackbar,
} from "@mui/material";
import { Edit, Delete, Add, Search } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../context/UserContext";
import { useItemContext } from "../../context/ItemsContext";
import { deleteUser, deleteItem } from "../../utills/utills";
import { styled } from "@mui/system";
import { useSnackbar } from "notistack";
import UserDashboard from "./UserDashboard";

const DashboardContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  padding: "16px",
  width: "100%",
});

const AdminDashboard = () => {
  const { userList, user } = useAuth();
  const { allItems, fetchItems } = useItemContext();
  const [sortedProducts, setSortedProducts] = useState([]);
  const [sortField, setSortField] = useState("");
  const [sortOrder, setSortOrder] = useState("asc");
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage] = useState(10);
  const [openDialog, setOpenDialog] = useState(false);
  const [deleteTarget, setDeleteTarget] = useState(null);
  const [deleteType, setDeleteType] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  useEffect(() => {
    const updatedProducts = allItems.map((product) => ({
      ...product,
      start_date: product.start_date ? new Date(product.start_date) : null,
      finish_date: product.finish_date ? new Date(product.finish_date) : null,
      availableTime:
        product.start_date && product.finish_date
          ? `${Math.floor(
              (new Date(product.finish_date) - Date.now()) /
                (1000 * 60 * 60 * 24)
            )} days`
          : "N/A",
    }));
    setSortedProducts(updatedProducts);
  }, [allItems]);

  // Handle sorting of products
  const handleSort = (field) => {
    const order = sortField === field && sortOrder === "asc" ? "desc" : "asc";
    setSortField(field);
    setSortOrder(order);
    const sorted = [...sortedProducts].sort((a, b) => {
      if (a[field] < b[field]) return order === "asc" ? -1 : 1;
      if (a[field] > b[field]) return order === "asc" ? 1 : -1;
      return 0;
    });
    setSortedProducts(sorted);
  };

  // Handle page change for pagination
  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  // Handle navigation to product page
  const handleImageClick = (id) => {
    navigate(`/shopping/${id}`);
  };

  // Open delete confirmation dialog
  const handleOpenDialog = (target, type) => {
    setDeleteTarget(target);
    setDeleteType(type);
    setOpenDialog(true);
  };

  // Close delete confirmation dialog
  const handleCloseDialog = () => {
    setOpenDialog(false);
    setDeleteTarget(null);
    setDeleteType(null);
  };

  // Handle delete item action
  const handleDeleteItem = async (item_id) => {
    try {
      await deleteItem(item_id);
      enqueueSnackbar("Item deleted successfully", { variant: "success" });
      window.location.reload();
      fetchItems();
    } catch (error) {
      console.log("Cannot delete items users has bought.", error);
    }
    handleCloseDialog();
  };

  // Handle delete user action
  const handleDeleteUser = async (user_id) => {
    try {
      await deleteUser(user_id);
      enqueueSnackbar("User deleted successfully", { variant: "success" });
      window.location.reload();
    } catch (error) {
      enqueueSnackbar("Failed to delete user: ", {
        variant: "error",
      });
    }
    handleCloseDialog();
  };

  // Handle search functionality
  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
  };

  // Filter products based on search query
  const filteredProducts = sortedProducts.filter((product) =>
    product.item_name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  // Pagination logic
  const indexOfLastProduct = currentPage * rowsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - rowsPerPage;
  const currentProducts = filteredProducts.slice(
    indexOfFirstProduct,
    indexOfLastProduct
  );

  return (
    <DashboardContainer>
      <div className="w-full flex items-center justify-between text-2xl font-semibold mb-6">
        <h1 className="text-4xl font-semibold">Admin Dashboard</h1>
      </div>
      <div className="w-full flex items-center justify-between mb-6">
        {/* Logo change section */}
        <div className="flex w-1/2">
          <label htmlFor="logoChange">
            <img
              src="https://img.freepik.com/premium-psd/premium-text-png-transparent-psd_873925-393170.jpg"
              alt="Change logo"
              className="w-48 rounded-lg cursor-pointer"
            />
          </label>
        </div>
        {/* Buttons section */}
        <div className="flex w-1/2 h-full items-center justify-evenly">
          <>
            {user.user_role === "superadmin" && (
              <Button
                variant="contained"
                onClick={() => navigate("/create-item")}
                color="primary"
                className="w-1/3"
                startIcon={<Add />}
              >
                Add New Product
              </Button>
            )}
            <Button
              onClick={() => navigate("/create-user")}
              variant="contained"
              color="primary"
              className="w-1/3"
              startIcon={<Add />}
            >
              Add New User
            </Button>
          </>
        </div>
      </div>
      {/* Search bar */}
      <div className="w-full mb-4">
        <TextField
          label="Search Items"
          variant="outlined"
          fullWidth
          value={searchQuery}
          onChange={handleSearch}
          InputProps={{
            startAdornment: (
              <Search position="start" style={{ marginRight: "8px" }} />
            ),
          }}
        />
      </div>
      {/* All Items */}
      <div className="w-full mb-10">
        <h1 className="text-2xl font-semibold mb-4">All Items</h1>
        <TableContainer component={Paper}>
          <Table className="w-full table-auto mb-4">
            <TableHead>
              <TableRow>
                <TableCell
                  onClick={() => handleSort("product_image")}
                  className="cursor-pointer"
                >
                  Image
                </TableCell>
                <TableCell
                  onClick={() => handleSort("item_name")}
                  className="cursor-pointer"
                >
                  Item Name
                </TableCell>
                <TableCell
                  onClick={() => handleSort("start_date")}
                  className="cursor-pointer"
                >
                  Start Date
                </TableCell>
                <TableCell
                  onClick={() => handleSort("finish_date")}
                  className="cursor-pointer"
                >
                  Expire Date
                </TableCell>
                <TableCell
                  onClick={() => handleSort("availableTime")}
                  className="cursor-pointer"
                >
                  Available Time
                </TableCell>
                <TableCell
                  onClick={() => handleSort("available_products")}
                  className="cursor-pointer"
                >
                  Available Items
                </TableCell>
                {user.user_role === "superadmin" && (
                  <TableCell>Operations</TableCell>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {currentProducts.map((product, index) => (
                <TableRow key={index}>
                  <TableCell>
                    <img
                      src={product.product_image}
                      alt={product.item_name}
                      className="w-12 h-12 cursor-pointer"
                      onClick={() => handleImageClick(product.item_id)}
                    />
                  </TableCell>
                  <TableCell>{product.item_name}</TableCell>
                  <TableCell>
                    {product.start_date
                      ? product.start_date.toLocaleDateString()
                      : "N/A"}
                  </TableCell>
                  <TableCell>
                    {product.finish_date
                      ? product.finish_date.toLocaleDateString()
                      : "N/A"}
                  </TableCell>
                  <TableCell>{product.availableTime}</TableCell>
                  <TableCell>{product.available_products}</TableCell>
                  <TableCell>
                    {user.user_role === "superadmin" && (
                      <>
                        <IconButton
                          color="primary"
                          onClick={() =>
                            navigate(`/product/edit/${product.item_id}`)
                          }
                        >
                          <Edit />
                        </IconButton>
                        <IconButton
                          color="secondary"
                          onClick={() =>
                            handleOpenDialog(product.item_id, "item")
                          }
                        >
                          <Delete />
                        </IconButton>
                      </>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Pagination
          count={Math.ceil(filteredProducts.length / rowsPerPage)}
          page={currentPage}
          onChange={handlePageChange}
          className="mt-4"
        />
      </div>
      {/* Admins */}
      <div className="w-full mb-10">
        <h1 className="text-2xl font-semibold mb-4">Admins</h1>
        <TableContainer component={Paper}>
          <Table className="w-full table-auto mb-4">
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Credits</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Type</TableCell>
                {user.user_role === "superadmin" && (
                  <TableCell>Operations</TableCell>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {userList &&
                userList
                  .filter(
                    (admin) =>
                      admin.user_role === "admin" ||
                      admin.user_role === "superadmin"
                  )
                  .map((admin, index) => (
                    <TableRow key={index}>
                      <TableCell
                        className="text-blue-500 cursor-pointer"
                        onClick={() =>
                          navigate(`/users/profile/${admin.user_id}`)
                        }
                      >
                        {admin.username}
                      </TableCell>
                      <TableCell>{admin.credit}</TableCell>
                      <TableCell>{admin.email}</TableCell>
                      <TableCell>{admin.user_role}</TableCell>
                      <TableCell>
                        {user.user_role === "superadmin" && (
                          <>
                            <IconButton
                              color="primary"
                              onClick={() =>
                                navigate(`/users/edit/${admin.user_id}`)
                              }
                            >
                              <Edit />
                            </IconButton>
                            <IconButton
                              color="secondary"
                              onClick={() =>
                                handleOpenDialog(admin.user_id, "admin")
                              }
                            >
                              <Delete />
                            </IconButton>
                          </>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <UserDashboard />
      {/* Delete Confirmation Dialog */}
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Confirm Delete"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this {deleteType}?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Cancel
          </Button>
          <Button
            onClick={() =>
              deleteType === "item"
                ? handleDeleteItem(deleteTarget)
                : handleDeleteUser(deleteTarget)
            }
            color="secondary"
            autoFocus
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar />
    </DashboardContainer>
  );
};

export default AdminDashboard;
