// File: CreateUser.js
import React, { useState } from "react";
import {
  TextField,
  Button,
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import { styled } from "@mui/system";
import { registerUser } from "../../../utills/utills";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../context/UserContext";

const FormContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  padding: "16px",
  width: "100%",
  maxWidth: "600px",
  borderRadius: "8px",
});

const CreateUser = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [userRole, setUserRole] = useState("user");
  const [mobile, setMobile] = useState("");
  const [credit, setCredit] = useState();
  const [confirmPassword, setConfirmPassword] = useState("");
  const { user } = useAuth();

  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      enqueueSnackbar("Passwords does not match", { variant: "warning" });
      return;
    }
    try {
      await registerUser(
        username,
        email,
        password,
        mobile,
        null, // Ensure fingerprint is sent as null
        userRole,
        credit
      );
      enqueueSnackbar("User created successfully", { variant: "success" });
      window.location.reload();
      navigate("/");
    } catch (error) {
      console.log("Error creating user:", error);
      enqueueSnackbar("Error creating user", { variant: "error" });
    }
  };

  return (
    user && (
      <FormContainer>
        <Typography variant="h4" component="h1" gutterBottom>
          Create User
        </Typography>
        <form onSubmit={handleSubmit} style={{ width: "100%" }}>
          <TextField
            label="Username"
            name="username"
            variant="outlined"
            fullWidth
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            margin="normal"
          />
          <TextField
            label="Mobile Number"
            name="mobile"
            variant="outlined"
            fullWidth
            value={mobile}
            onChange={(e) => setMobile(e.target.value)}
            margin="normal"
          />
          <TextField
            label="Email"
            name="email"
            variant="outlined"
            fullWidth
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            margin="normal"
          />
          <TextField
            label="Password"
            name="password"
            type="password"
            variant="outlined"
            fullWidth
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            margin="normal"
          />
          <TextField
            label="Confirm Password"
            name="confirm_password"
            type="password"
            variant="outlined"
            fullWidth
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            margin="normal"
          />
          <RadioGroup
            name="user_role"
            value={userRole}
            onChange={(e) => setUserRole(e.target.value)}
            row
          >
            <FormControlLabel value="user" control={<Radio />} label="User" />
            {user.user_role === "superadmin" && (
              <>
                <FormControlLabel
                  value="admin"
                  control={<Radio />}
                  label="Admin"
                />
                <FormControlLabel
                  value="superadmin"
                  control={<Radio />}
                  label="Super Admin"
                />
              </>
            )}
          </RadioGroup>

          {userRole === "admin" ? (
            <TextField
              label="Credit Balance"
              name="credit"
              variant="outlined"
              fullWidth
              value={credit}
              onChange={(e) => setCredit(e.target.value)}
              margin="normal"
              type="number"
            />
          ) : null}

          <Button
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
            style={{ marginTop: "16px" }}
          >
            Create User
          </Button>
        </form>
      </FormContainer>
    )
  );
};

export default CreateUser;
