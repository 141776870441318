// UserProfile.js

import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useAuth } from "../../../context/UserContext";
import {
  Button,
  Grid,
  Card,
  CardContent,
  CardMedia,
  Typography,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Box,
} from "@mui/material";
import {
  Email as EmailIcon,
  Lock as LockIcon,
  Add as AddIcon,
  Delete as DeleteIcon,
  Edit as EditIcon,
  MobileFriendly as MobileIcon,
  Fingerprint as FingerprintIcon,
} from "@mui/icons-material";
import {
  deleteUser,
  updateUser,
  updateProductCredentials,
  addProductToPurchase,
  deletePurchase,
  updatePurchaseDates,
} from "../../../utills/utills.js";
import { useCartContext } from "../../../context/cartContext";
import { useItemContext } from "../../../context/ItemsContext";
import { useSnackbar } from "notistack";

const UserProfile = () => {
  const { user_id } = useParams();
  const { userList, user } = useAuth();
  const [userOfProfile, setUserOfProfile] = useState(null);
  const [products, setProducts] = useState([]);
  const [openAddDialog, setOpenAddDialog] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openDeleteUserDialog, setOpenDeleteUserDialog] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [selectedProductId, setSelectedProductId] = useState("");
  const [initialSelectedProduct, setInitialSelectedProduct] = useState(null);
  const [adminId, setAdminId] = useState(null);
  const navigate = useNavigate();

  const { allProducts } = useItemContext();
  const { orders } = useCartContext();

  const { enqueueSnackbar } = useSnackbar();

  // Get adminId
  useEffect(() => {
    if (user) {
      const admin_id = user.user_id;
      setAdminId(admin_id);
    }
  }, [user]);

  // Fetch user details based on user_id from URL params
  useEffect(() => {
    const selectedUser = userList.find((u) => u.user_id === parseInt(user_id));
    setUserOfProfile(selectedUser);
  }, [user_id, userList]);

  // Fetch user's purchased products
  useEffect(() => {
    if (userOfProfile) {
      const myPurchases = orders.filter(
        (order) =>
          order.status === "accepted" &&
          new Date(order.expire_date) > new Date() &&
          order.user_id === userOfProfile.user_id
      );

      const productIds = myPurchases.map((purchase) => purchase.product_id);

      const myItems = allProducts
        .filter((product) => productIds.includes(product.product_id))
        .map((product) => {
          const order = myPurchases.find(
            (purchase) => purchase.product_id === product.product_id
          );
          return {
            ...product,
            start_date: order.purchase_date,
            finish_date: order.expire_date,
            purchase_id: order.purchase_id,
          };
        });

      setProducts(myItems);
    }
  }, [userOfProfile, orders, allProducts]);

  // Handle user deletion
  const handleDeleteUser = async () => {
    try {
      await deleteUser(userOfProfile.user_id);
      enqueueSnackbar("User deleted successfully", { variant: "success" });
      navigate("/");
    } catch (error) {
      enqueueSnackbar("Failed to delete user: " + error.message, {
        variant: "error",
      });
      console.error("Error deleting user:", error);
    }
  };

  // Handle user reset (clear fingerprint)
  const handleResetUser = async () => {
    try {
      await updateUser(userOfProfile.user_id, { fingerprint: null });
      enqueueSnackbar("User reset successfully", { variant: "success" });
      setUserOfProfile((prev) => ({ ...prev, fingerprint: null }));
    } catch (error) {
      console.error("Error resetting user:", error);
      enqueueSnackbar("Failed to reset user: " + error.message, {
        variant: "error",
      });
    }
  };

  // Handle adding a new product to the user's purchases
  const handleAddProduct = async () => {
    try {
      const productToAdd = allProducts.find(
        (product) => product.product_id === parseInt(selectedProductId)
      );
      await addProductToPurchase(
        userOfProfile.user_id,
        productToAdd.product_id,
        adminId
      );
      setProducts([
        ...products,
        { ...productToAdd, product_id: products.length + 1 },
      ]);
      setOpenAddDialog(false);
      enqueueSnackbar("Product added to user", { variant: "success" });
      window.location.reload();
    } catch (error) {
      console.error("Error adding product:", error);
      enqueueSnackbar("Failed to add product: " + error.message, {
        variant: "error",
      });
    }
  };

  // Handle editing product details
  const handleEditProduct = async () => {
    const changedFields = {};
    if (
      selectedProduct.item_email !== initialSelectedProduct.item_email ||
      selectedProduct.item_password !== initialSelectedProduct.item_password
    ) {
      if (selectedProduct.item_email !== initialSelectedProduct.item_email) {
        changedFields.item_email = selectedProduct.item_email;
      }
      if (
        selectedProduct.item_password !== initialSelectedProduct.item_password
      ) {
        changedFields.item_password = selectedProduct.item_password;
      }
    }

    const changedDateFields = {};
    if (selectedProduct.finish_date !== initialSelectedProduct.finish_date) {
      changedDateFields.expire_date = selectedProduct.finish_date;
    }

    try {
      if (Object.keys(changedFields).length > 0) {
        await updateProductCredentials(
          selectedProduct.product_id,
          changedFields
        );
      }
      if (Object.keys(changedDateFields).length > 0) {
        await updatePurchaseDates(
          selectedProduct.purchase_id,
          changedDateFields
        );
      }

      setProducts(
        products.map((product) =>
          product.product_id === selectedProduct.product_id
            ? selectedProduct
            : product
        )
      );
      setOpenEditDialog(false);
      enqueueSnackbar("Product updated successfully", { variant: "success" });
      window.location.reload();
    } catch (error) {
      console.error("Error updating product:", error);
      enqueueSnackbar("Failed to update product: " + error.message, {
        variant: "error",
      });
    }
  };

  // Handle deleting a product from user's purchases
  const handleDeleteProduct = async () => {
    try {
      await deletePurchase(selectedProduct.purchase_id); // Use purchase_id
      setProducts(
        products.filter(
          (product) => product.purchase_id !== selectedProduct.purchase_id // Use purchase_id
        )
      );
      setOpenDeleteDialog(false);
      enqueueSnackbar("Product deleted successfully", { variant: "success" });
      window.location.reload();
    } catch (error) {
      console.error("Error deleting product:", error);
      enqueueSnackbar("Failed to delete product: " + error.message, {
        variant: "error",
      });
    }
  };

  // Dialog handlers for adding, editing, deleting products and user
  const handleOpenAddDialog = () => setOpenAddDialog(true);
  const handleCloseAddDialog = () => setOpenAddDialog(false);

  const handleOpenEditDialog = (product) => {
    setSelectedProduct(product);
    setInitialSelectedProduct({ ...product });
    setOpenEditDialog(true);
  };
  const handleCloseEditDialog = () => setOpenEditDialog(false);

  const handleOpenDeleteDialog = (product) => {
    setSelectedProduct(product);
    setOpenDeleteDialog(true);
  };
  const handleCloseDeleteDialog = () => setOpenDeleteDialog(false);

  const handleOpenDeleteUserDialog = () => setOpenDeleteUserDialog(true);
  const handleCloseDeleteUserDialog = () => setOpenDeleteUserDialog(false);

  // Format date to a readable format
  const formatDate = (date) => new Date(date).toLocaleDateString();

  if (!userOfProfile) return <div>Loading...</div>;

  return (
    userOfProfile && (
      <Box className="p-4" sx={{  margin: "0 " }}>
        {/* User Details Section */}
        <Typography variant="h4" component="h1" gutterBottom>
          {userOfProfile.username}'s Profile
        </Typography>
        <Box
          className="mb-4"
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 2,
            borderBottom: "1px solid #ccc",
            paddingBottom: 2,
            marginBottom: 2,
          }}
        >
          <Typography variant="h5" component="h2" gutterBottom>
            User Details
          </Typography>
          <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
            <EmailIcon />
            <Typography>
              <strong>Email:</strong> {userOfProfile.email}
            </Typography>
          </Box>
          <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
            <MobileIcon />
            <Typography>
              <strong>Mobile No:</strong> {userOfProfile.mobile || "N/A"}
            </Typography>
          </Box>
          <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
            <FingerprintIcon />
            <Typography>
              <strong>Device:</strong> {userOfProfile.fingerprint || "N/A"}
            </Typography>
          </Box>
        </Box>

        {/* Bought Products Section */}
        <Box className="mb-4">
          <Typography
            variant="h5"
            component="h2"
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            Bought Products
            <Button
              variant="contained"
              color="primary"
              startIcon={<AddIcon />}
              onClick={handleOpenAddDialog}
            >
              Add New Product
            </Button>
          </Typography>
          {products.length > 0 ? (
            <Grid container spacing={2} sx={{ marginTop: 2, minHeight: 250 }}>
              {products.map((product) => (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={4}
                  lg={3}
                  key={product.product_id}
                >
                  <Card
                    sx={{
                      height: "100%",
                      display: "flex",
                      flexDirection: "column",
                      position: "relative",
                      boxShadow: 3,
                      transition: "transform 0.3s ease-in-out",
                      "&:hover": { transform: "scale(1.05)" },
                    }}
                  >
                    <CardMedia
                      component="img"
                      sx={{ height: "100%", objectFit: "cover" }}
                      image={product.product_image}
                      alt={product.product_name}
                    />
                    <CardContent
                      sx={{ flexGrow: 1 }}
                      className="absolute inset-0 flex flex-col items-center justify-center bg-white bg-opacity-75 opacity-0 hover:opacity-100 transition-opacity duration-300"
                    >
                      <Typography
                        sx={{ fontWeight: "bold" }}
                        variant="h6"
                        component="div"
                      >
                        {product.product_name}
                      </Typography>
                      <Typography sx={{ fontWeight: "bold" }} variant="body2">
                        Start Date: {formatDate(product.start_date)}
                      </Typography>
                      <Typography sx={{ fontWeight: "bold" }} variant="body2">
                        Expire Date: {formatDate(product.finish_date)}
                      </Typography>
                      {product.item_email && (
                        <Typography
                          sx={{ fontWeight: "bold" }}
                          variant="body2"
                          className="cursor-pointer text-rose-600 underline flex items-center"
                        >
                          <EmailIcon sx={{ marginRight: 1 }} />
                          {product.item_email}
                          <IconButton size="small"></IconButton>
                        </Typography>
                      )}
                      {product.item_password && (
                        <Typography
                          sx={{ fontWeight: "bold" }}
                          variant="body2"
                          className="cursor-pointer text-rose-600 underline flex items-center"
                        >
                          <LockIcon sx={{ marginRight: 1 }} />
                          *******
                          <IconButton size="small"></IconButton>
                        </Typography>
                      )}
                      <Button
                        variant="contained"
                        color="secondary"
                        startIcon={<EditIcon />}
                        onClick={() => handleOpenEditDialog(product)}
                        sx={{ mt: 1 }}
                      >
                        Edit
                      </Button>
                      <Button
                        variant="contained"
                        color="error"
                        startIcon={<DeleteIcon />}
                        onClick={() => handleOpenDeleteDialog(product)}
                        sx={{ mt: 1 }}
                      >
                        Delete
                      </Button>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
          ) : (
            <Typography>No products bought by this user.</Typography>
          )}
        </Box>

        {/* User Actions Section */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-around",
            marginBottom: 2,
          }}
        >
          <Button
            variant="contained"
            color="primary"
            onClick={() => navigate(`/users/edit/${userOfProfile.user_id}`)}
            className="mr-2"
          >
            Edit User
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={handleOpenDeleteUserDialog}
            className="mr-2"
          >
            Delete User
          </Button>
          <Button variant="contained" color="default" onClick={handleResetUser}>
            Reset User
          </Button>
        </Box>

        {/* Add Product Dialog */}
        <Dialog open={openAddDialog} onClose={handleCloseAddDialog}>
          <DialogTitle>Add New Product For User</DialogTitle>
          <DialogContent>
            <FormControl fullWidth margin="normal">
              <InputLabel id="select-product-label">Product</InputLabel>
              <Select
                labelId="select-product-label"
                value={selectedProductId}
                onChange={(e) => setSelectedProductId(e.target.value)}
                label="Product"
              >
                {allProducts
                  .filter((product) => product.availability)
                  .map((product) => (
                    <MenuItem
                      key={product.product_id}
                      value={product.product_id}
                    >
                      {product.product_name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseAddDialog} color="primary">
              Cancel
            </Button>
            <Button onClick={handleAddProduct} color="primary">
              Add
            </Button>
          </DialogActions>
        </Dialog>

        {/* Edit Product Dialog */}
        {selectedProduct && (
          <Dialog open={openEditDialog} onClose={handleCloseEditDialog}>
            <DialogTitle>Edit Product</DialogTitle>
            <DialogContent>
              <TextField
                label="Expire Date"
                type="date"
                fullWidth
                margin="normal"
                InputLabelProps={{ shrink: true }}
                value={selectedProduct.finish_date}
                onChange={(e) =>
                  setSelectedProduct({
                    ...selectedProduct,
                    finish_date: e.target.value,
                  })
                }
              />
              <TextField
                label="Email"
                fullWidth
                margin="normal"
                value={selectedProduct.item_email}
                onChange={(e) =>
                  setSelectedProduct({
                    ...selectedProduct,
                    item_email: e.target.value,
                  })
                }
              />
              <TextField
                label="Password"
                fullWidth
                margin="normal"
                value={selectedProduct.item_password}
                onChange={(e) =>
                  setSelectedProduct({
                    ...selectedProduct,
                    item_password: e.target.value,
                  })
                }
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseEditDialog} color="primary">
                Cancel
              </Button>
              <Button onClick={handleEditProduct} color="primary">
                Save
              </Button>
            </DialogActions>
          </Dialog>
        )}

        {/* Delete Product Dialog */}
        <Dialog open={openDeleteDialog} onClose={handleCloseDeleteDialog}>
          <DialogTitle>Delete Product</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are you sure you want to delete this product?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDeleteDialog} color="primary">
              Cancel
            </Button>
            <Button onClick={handleDeleteProduct} color="secondary">
              Delete
            </Button>
          </DialogActions>
        </Dialog>

        {/* Delete User Dialog */}
        <Dialog
          open={openDeleteUserDialog}
          onClose={handleCloseDeleteUserDialog}
        >
          <DialogTitle>Delete User</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are you sure you want to delete this user?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDeleteUserDialog} color="primary">
              Cancel
            </Button>
            <Button onClick={handleDeleteUser} color="secondary">
              Delete
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    )
  );
};

export default UserProfile;
