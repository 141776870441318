// File: EditUser.js
import React, { useState, useEffect } from "react";
import { TextField, Button, Typography } from "@mui/material";
import { styled } from "@mui/system";
import { useNavigate, useParams } from "react-router-dom";
import { useAuth } from "../../../context/UserContext";
import { updateUser } from "../../../utills/utills";
import { useSnackbar } from "notistack";

const FormContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  padding: "16px",
  width: "100%",
  maxWidth: "600px",
  borderRadius: "8px",
});

const EditUser = () => {
  const { id } = useParams();
  const { userList, user } = useAuth();
  const [userEdit, setUserEdit] = useState(null);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  useEffect(() => {
    const userToEdit = userList.find((u) => u.user_id === parseInt(id));
    if (userToEdit) {
      setUserEdit(userToEdit);
    }
  }, [id, userList]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserEdit((prevUser) => ({
      ...prevUser,
      [name]: value,
    }));
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      enqueueSnackbar("Passwords does not match", { variant: "warning" });
      return;
    }
    const updatedUser = { ...userEdit };
    if (password) {
      updatedUser.password = password;
    }
    try {
      await updateUser(userEdit.user_id, updatedUser);
      enqueueSnackbar("User updated successfully", { variant: "success" });
      window.location.reload();
      navigate("/");
    } catch (error) {
      console.log("Error updating user:", error);
      enqueueSnackbar("Error updating user", { variant: "error" });
    }
  };

  if (!userEdit) {
    return <div>Loading...</div>;
  }

  return (
    <FormContainer>
      <Typography variant="h4" component="h1" gutterBottom>
        Edit User
      </Typography>
      <form onSubmit={handleSubmit} style={{ width: "100%" }}>
        <TextField
          label="Username"
          name="username"
          variant="outlined"
          fullWidth
          value={userEdit.username}
          onChange={handleChange}
          margin="normal"
        />
        <TextField
          label="Email"
          name="email"
          variant="outlined"
          fullWidth
          value={userEdit.email}
          onChange={handleChange}
          margin="normal"
        />
        <TextField
          label="Mobile no"
          name="mobile"
          variant="outlined"
          fullWidth
          value={userEdit.mobile}
          onChange={handleChange}
          margin="normal"
        />
        {user.user_role === "user" ? (
          <></>
        ) : (
          <TextField
            label="Device"
            name="fingerprint"
            variant="outlined"
            fullWidth
            value={userEdit.fingerprint}
            onChange={handleChange}
            margin="normal"
          />
        )}
        <TextField
          label="New Password"
          name="password"
          type="password"
          variant="outlined"
          fullWidth
          value={password}
          onChange={handlePasswordChange}
          margin="normal"
        />
        <TextField
          label="Confirm Password"
          name="confirm_password"
          type="password"
          variant="outlined"
          fullWidth
          value={confirmPassword}
          onChange={handleConfirmPasswordChange}
          margin="normal"
        />
        {user.user_role === "superadmin" && userEdit.user_role === "admin" ? (
          <TextField
            label="Credit Balance"
            name="credit"
            variant="outlined"
            fullWidth
            value={userEdit.credit}
            onChange={handleChange}
            margin="normal"
            type="number"
          />
        ) : null}
        <Button
          type="submit"
          variant="contained"
          color="primary"
          fullWidth
          style={{ marginTop: "16px" }}
        >
          Save User
        </Button>
      </form>
    </FormContainer>
  );
};

export default EditUser;
