import React, { useState, useEffect } from "react";
import {
  TextField,
  Button,
  Input,
  IconButton,
  InputLabel,
  Typography,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio,
  InputAdornment,
} from "@mui/material";
import { CloudUpload, AccountBox, Person, Lock } from "@mui/icons-material";
import { styled } from "@mui/system";
import { useNavigate, useParams } from "react-router-dom";
import { useItemContext } from "../../../context/ItemsContext";
import { updateItem } from "../../../utills/utills";
import { useSnackbar } from "notistack";

const FormContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  padding: "16px",
  width: "100%",
  maxWidth: "600px",
  borderRadius: "8px",
  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
  backgroundColor: "#fff",
});

const UploadContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  marginBottom: "16px",
});

const ImagePreview = styled("img")({
  width: "100%",
  maxHeight: "300px",
  objectFit: "cover",
  marginTop: "8px",
  borderRadius: "4px",
});

const EditProduct = () => {
  const { id } = useParams();
  const { allItems } = useItemContext();
  const [originalProduct, setOriginalProduct] = useState(null);
  const [product, setProduct] = useState(null);
  const [imagePreview, setImagePreview] = useState("");
  const [imageSource, setImageSource] = useState("file");

  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  useEffect(() => {
    if (allItems) {
      const productToEdit = allItems.find((p) => p.item_id === parseInt(id));
      if (productToEdit) {
        setOriginalProduct(productToEdit);
        setProduct(productToEdit);
        setImagePreview(productToEdit.product_image);
      }
    }
  }, [id, allItems]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setProduct((prevProduct) => ({
      ...prevProduct,
      [name]: value,
    }));
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const imageUrl = URL.createObjectURL(file);
      setProduct((prevProduct) => ({
        ...prevProduct,
        product_image: imageUrl,
      }));
      setImagePreview(imageUrl);
    }
  };

  const handleImageUrlChange = (e) => {
    const { value } = e.target;
    setProduct((prevProduct) => ({
      ...prevProduct,
      product_image: value,
    }));
    setImagePreview(value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const updatedFields = Object.keys(product).reduce((acc, key) => {
      if (product[key] !== originalProduct[key]) {
        acc[key] = product[key];
      }
      return acc;
    }, {});

    if (Object.keys(updatedFields).length > 0) {
      try {
        await updateItem(id, updatedFields);
        enqueueSnackbar("Item Edited successfully", { variant: "success" });
        window.location.reload();
        navigate("/");
      } catch (error) {
        console.log(error);
      }
    } else {
      enqueueSnackbar("Error editing", { variant: "error" });
    }
  };

  if (!product) {
    return <div>Loading...</div>;
  }

  return (
    <FormContainer>
      <Typography variant="h4" component="h1" gutterBottom>
        Edit Product
      </Typography>
      <form onSubmit={handleSubmit} style={{ width: "100%" }}>
        <UploadContainer>
          <InputLabel htmlFor="upload-image">
            <IconButton
              color="primary"
              aria-label="upload picture"
              component="span"
              style={{ fontSize: "48px" }}
            >
              <CloudUpload fontSize="inherit" />
            </IconButton>
          </InputLabel>
          <RadioGroup
            row
            name="imageSource"
            value={imageSource}
            onChange={(e) => setImageSource(e.target.value)}
          >
            <FormControlLabel
              value="file"
              control={<Radio />}
              label="Upload File"
            />
            <FormControlLabel
              value="url"
              control={<Radio />}
              label="Image URL"
            />
          </RadioGroup>
          {imageSource === "file" ? (
            <Input
              id="upload-image"
              type="file"
              inputProps={{ accept: "image/*" }}
              style={{ display: "none" }}
              onChange={handleImageChange}
            />
          ) : (
            <TextField
              label="Image URL"
              name="product_image"
              variant="outlined"
              fullWidth
              value={product.product_image}
              onChange={handleImageUrlChange}
              margin="normal"
            />
          )}
          {imagePreview && (
            <ImagePreview src={imagePreview} alt="Product Preview" />
          )}
        </UploadContainer>

        <TextField
          label="Item Name"
          name="item_name"
          variant="outlined"
          fullWidth
          value={product.item_name}
          onChange={handleChange}
          margin="normal"
        />
        <TextField
          label="Available Products"
          name="available_products"
          type="number"
          variant="outlined"
          fullWidth
          value={product.available_products}
          onChange={handleChange}
          margin="normal"
        />
        <TextField
          label="Price"
          name="price"
          type="number"
          variant="outlined"
          fullWidth
          value={product.price}
          onChange={handleChange}
          margin="normal"
          InputProps={{
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
          }}
        />
        <TextField
          label="E-Seller Price"
          name="eseller_price"
          type="number"
          variant="outlined"
          fullWidth
          value={product.eseller_price}
          onChange={handleChange}
          margin="normal"
          InputProps={{
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
          }}
        />
        <TextField
          label="Description"
          name="description"
          variant="outlined"
          fullWidth
          multiline
          rows={4}
          value={product.description}
          onChange={handleChange}
          margin="normal"
        />
        <TextField
          label="Start Date"
          name="start_date"
          type="date"
          variant="outlined"
          fullWidth
          value={product.start_date ? product.start_date : ""}
          onChange={handleChange}
          InputLabelProps={{
            shrink: true,
          }}
          margin="normal"
        />
        <TextField
          label="Finish Date"
          name="finish_date"
          type="date"
          variant="outlined"
          fullWidth
          value={product.finish_date ? product.finish_date : ""}
          onChange={handleChange}
          InputLabelProps={{
            shrink: true,
          }}
          margin="normal"
        />
        <FormControl component="fieldset" margin="normal">
          <Typography variant="h6" component="legend">
            Package Types
          </Typography>
          <RadioGroup
            name="item_type"
            value={product.item_type}
            onChange={handleChange}
          >
            <FormControlLabel
              value="direct/shared"
              control={<Radio />}
              label={
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Person style={{ marginRight: "8px" }} />
                  Direct/Shared
                </div>
              }
            />
            <FormControlLabel
              value="direct/private"
              control={<Radio />}
              label={
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Lock style={{ marginRight: "8px" }} />
                  Direct/Private
                </div>
              }
            />
            <FormControlLabel
              value="own account upgrade"
              control={<Radio />}
              label={
                <div style={{ display: "flex", alignItems: "center" }}>
                  <AccountBox style={{ marginRight: "8px" }} />
                  Own Account Upgrade
                </div>
              }
            />
          </RadioGroup>
        </FormControl>
        <TextField
          label="Item URL"
          name="item_url"
          variant="outlined"
          fullWidth
          value={product.item_url}
          onChange={handleChange}
          margin="normal"
        />
        <TextField
          label="Package Email"
          name="item_email"
          variant="outlined"
          fullWidth
          value={product.item_email}
          onChange={handleChange}
          margin="normal"
        />
        <TextField
          label="Package Password"
          name="item_password"
          type="password"
          variant="outlined"
          fullWidth
          value={product.item_password}
          onChange={handleChange}
          margin="normal"
        />
        <Button
          type="submit"
          variant="contained"
          color="primary"
          fullWidth
          style={{ marginTop: "16px" }}
        >
          Save Product
        </Button>
      </form>
    </FormContainer>
  );
};

export default EditProduct;
