import React from "react";
import UserDashboard from "./UserDashboard";
import { useAuth } from "../../context/UserContext";
import AdminDashboard from "./AdminDashboard";

const Dashboard = () => {
  const { user } = useAuth();

  if (!user) {
    return <UserDashboard />;
  }

  return (
    <>{user.user_role === "user" ? <UserDashboard /> : <AdminDashboard />}</>
  );
};

export default Dashboard;
