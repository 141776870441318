import React, { useState, useEffect } from "react";
import {
  TextField,
  Button,
  Typography,
  CircularProgress,
  InputAdornment,
} from "@mui/material";
import { motion, AnimatePresence } from "framer-motion";
import loginImage from "../../assets/loginImg.jpg";
import registerImage from "../../assets/registerImg.avif";
import { useAuth } from "../../context/UserContext";
import { loginUser, registerUser, updateUser } from "../../utills/utills";
import "tailwindcss/tailwind.css";
import FingerprintJS from "@fingerprintjs/fingerprintjs";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import PhoneIcon from "@mui/icons-material/Phone";
import ForgotPassword from "./ForgotPassword";

const LoginRegister = () => {
  const [isLogin, setIsLogin] = useState(true);
  const [isForgotPassword, setIsForgotPassword] = useState(false);
  const [username, setUsername] = useState("");
  const [mobile, setMobile] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { login } = useAuth();
  const [fingerprint, setFingerprint] = useState("");
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  useEffect(() => {
    const getFingerprint = async () => {
      const fp = await FingerprintJS.load();
      const result = await fp.get();
      setFingerprint(result.visitorId);
    };
    getFingerprint();
  }, []);

  const handleFormSwitch = () => {
    setIsLogin(!isLogin);
    setIsForgotPassword(false);
    resetForm();
  };

  const handleForgotPassword = () => {
    setIsForgotPassword(true);
    resetForm();
  };

  const resetForm = () => {
    setUsername("");
    setEmail("");
    setPassword("");
    setMobile("");
  };

  const handleLogin = async () => {
    setLoading(true);
    try {
      const data = await loginUser(email, password, fingerprint);
      if (!data.user.fingerprint) {
        await updateUser(data.user.user_id, { fingerprint });
        data.user.fingerprint = fingerprint;
      }
      if (data.user.fingerprint.trim() === fingerprint.trim()) {
        login(data.user);
        localStorage.setItem("token", data.token);
        enqueueSnackbar("Login successful!", { variant: "success" });
        setTimeout(() => {
          localStorage.removeItem("token");
        }, 24 * 60 * 60 * 1000); // 24 hours
        navigate("/");
      } else {
        enqueueSnackbar("Login attempt from different device detected", {
          variant: "error",
        });
      }
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
    } finally {
      setLoading(false);
    }
  };

  const handleRegister = async () => {
    setLoading(true);
    try {
      await registerUser(username, email, password, mobile, fingerprint);
      enqueueSnackbar("Registration successful! Please login.", {
        variant: "success",
      });
      handleFormSwitch();
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div
      className="flex justify-center items-center min-h-screen bg-cover bg-center relative"
      style={{
        backgroundImage: `url(${isLogin ? loginImage : registerImage})`,
      }}
    >
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1 }}
        className="absolute top-4 left-4 text-white text-2xl font-bold"
      >
        Premium
      </motion.div>
      <AnimatePresence>
        <motion.div
          key={
            isLogin
              ? "login"
              : isForgotPassword
              ? "forgot-password"
              : "register"
          }
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: 50 }}
          transition={{ duration: 0.5 }}
          className="bg-white bg-opacity-90 p-8 rounded-lg shadow-lg z-10 w-[400px]"
        >
          {isForgotPassword ? (
            <ForgotPassword
              onCancel={() => setIsForgotPassword(false)}
              onSwitch={handleFormSwitch}
            />
          ) : (
            <>
              <Typography variant="h4" className="mb-6  text-center">
                {isLogin ? "Login" : "Register"}
              </Typography>
              <form className="mt-5">
                {!isLogin && (
                  <>
                    <TextField
                      label="Username"
                      variant="outlined"
                      fullWidth
                      margin="normal"
                      value={username}
                      onChange={(e) => setUsername(e.target.value)}
                      className="mb-4"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <AccountCircleIcon />
                          </InputAdornment>
                        ),
                      }}
                    />
                    <TextField
                      label="Mobile Number"
                      variant="outlined"
                      fullWidth
                      margin="normal"
                      value={mobile}
                      onChange={(e) => setMobile(e.target.value)}
                      className="mb-4"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <PhoneIcon />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </>
                )}
                <TextField
                  label="Email"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="mb-4"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <MailOutlineIcon />
                      </InputAdornment>
                    ),
                  }}
                />
                <TextField
                  label="Password"
                  type="password"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className="mb-4"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <LockOpenIcon />
                      </InputAdornment>
                    ),
                  }}
                />
                {loading ? (
                  <CircularProgress className="mt-4" />
                ) : (
                  <>
                    {isLogin ? (
                      <Button
                        variant="contained"
                        color="primary"
                        fullWidth
                        onClick={handleLogin}
                        className="mt-4"
                      >
                        Login
                      </Button>
                    ) : (
                      <Button
                        variant="contained"
                        color="primary"
                        fullWidth
                        onClick={handleRegister}
                        className="mt-4"
                      >
                        Register
                      </Button>
                    )}
                  </>
                )}
              </form>
              {isLogin && (
                <Button
                  onClick={handleForgotPassword}
                  className="mt-4 w-full text-center"
                >
                  Forgot Password?
                </Button>
              )}
              <Button
                onClick={handleFormSwitch}
                className="mt-4 w-full text-center"
              >
                {isLogin
                  ? "Don't have an account? Register"
                  : "Already have an account? Login"}
              </Button>
            </>
          )}
        </motion.div>
      </AnimatePresence>
    </div>
  );
};

export default LoginRegister;
