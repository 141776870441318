import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { useItemContext } from "../../context/ItemsContext";
import { IconButton } from "@mui/material";
import { ArrowBack, ArrowForward } from "@mui/icons-material";
import { Circle } from "@mui/icons-material";

const Shop = () => {
  const { allItems } = useItemContext();
  const [filteredItems, setFilteredItems] = useState(allItems);
  const [page, setPage] = useState(1);
  const itemsPerPage = 15;

  useEffect(() => {
    setFilteredItems(allItems);
  }, [allItems]);

  const handleSearchChange = (event) => {
    const value = event.target.value;
    if (value) {
      const newItems = allItems.filter((item) =>
        item.item_name.toLowerCase().includes(value.toLowerCase())
      );
      setFilteredItems(newItems);
    } else {
      setFilteredItems(allItems);
    }
  };

  const handleChangePage = (event, value) => {
    setPage(value);
  };

  const paginatedItems = filteredItems.slice(
    (page - 1) * itemsPerPage,
    page * itemsPerPage
  );

  const carouselImages = [
    "https://res.cloudinary.com/jerrick/image/upload/v1678179563/6406fceb9da86d001dd1863c.png",
    
  ];

  return (
    <div className="w-full flex flex-col p-4">
      <div className="w-full flex items-center justify-between text-2xl font-semibold mb-6">
        <h1 className="text-4xl font-semibold">Shop Here</h1>
      </div>
      <Carousel
        showThumbs={false}
        autoPlay
        infiniteLoop
        showStatus={false}
        className="mb-20 rounded-lg"
        renderIndicator={(onClickHandler, isSelected, index, label) => {
          const defStyle = {
            marginLeft: 20,
            cursor: "pointer",
            display: "inline-block",
          };
          const style = isSelected
            ? { ...defStyle, color: "blue" }
            : { ...defStyle };

          return (
            <span
              style={style}
              onClick={onClickHandler}
              onKeyDown={onClickHandler}
              role="button"
              tabIndex={0}
              aria-label={`${label} ${index + 1}`}
              className="focus:outline-none"
            >
              <Circle
                className={isSelected ? "text-blue-500" : "text-gray-500"}
              />
            </span>
          );
        }}
      >
        {carouselImages.map((src, index) => (
          <div key={index}>
            <img
              src={src}
              alt={`carousel-${index}`}
              className="h-48 md:h-80 lg:h-96 w-full object-cover"
            />
          </div>
        ))}
      </Carousel>

      <div className="my-4 lg:w-2/3">
        <input
          type="text"
          placeholder="Search Products"
          className="w-full p-3 border border-gray-300 rounded"
          onChange={handleSearchChange}
        />
      </div>

      {filteredItems.length === 0 ? (
        <h1 className="text-center text-2xl font-semibold mt-10">
          No Items Available
        </h1>
      ) : (
        <>
          <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-3 lg:grid-cols-4 gap-4">
            {paginatedItems.map((product) => (
              <Link
                key={product.item_id}
                to={`/shopping/${product.item_id}`}
                className="text-decoration-none"
              >
                <div className="relative  mx-auto mb-8 bg-white rounded-xl shadow-md overflow-hidden hover:scale-105 transition-transform duration-300 flex flex-col justify-between h-48 min-h-28 cursor-pointer">
                  {product.available_products <= 0 && (
                    <div className="absolute w-50 top-10 right-[-25px] bg-red-600 text-white px-10 py-1 text-xs font-bold transform rotate-45 translate-x-4 -translate-y-4">
                      Out of Stock
                    </div>
                  )}
                  <img
                    className="h-4/5 w-full object-cover"
                    src={product.product_image}
                    alt={product.item_name}
                  />
                  <div className="text-center p-2">
                    <h2 className="text-sm sm:text-base md:text-lg lg:text-xl font-bold">
                      {product.item_name}
                    </h2>
                  </div>
                </div>
              </Link>
            ))}
          </div>

          <div className="flex justify-center mt-10 mb-10 flex-wrap">
            <IconButton
              className={`px-3 py-1 mx-1 rounded ${
                page === 1 ? "bg-gray-300" : "bg-blue-500 text-white"
              }`}
              disabled={page === 1}
              onClick={() => handleChangePage(null, page - 1)}
            >
              <ArrowBack className="text-lg" />
            </IconButton>
            {[
              ...Array(Math.ceil(filteredItems.length / itemsPerPage)).keys(),
            ].map((p) => (
              <button
                key={p + 1}
                className={`px-3 py-1 mx-1 rounded ${
                  page === p + 1 ? "bg-blue-500 text-white" : "bg-gray-300"
                }`}
                onClick={() => handleChangePage(null, p + 1)}
              >
                {p + 1}
              </button>
            ))}
            <IconButton
              className={`px-3 py-1 mx-1 rounded ${
                page === Math.ceil(filteredItems.length / itemsPerPage)
                  ? "bg-gray-300"
                  : "bg-blue-500 text-white"
              }`}
              disabled={page === Math.ceil(filteredItems.length / itemsPerPage)}
              onClick={() => handleChangePage(null, page + 1)}
            >
              <ArrowForward className="text-lg" />
            </IconButton>
          </div>
        </>
      )}
    </div>
  );
};

export default Shop;
