import React from "react";
import { Box, Container, Typography, Link } from "@mui/material";

const Footer = () => {
  return (
    <Box
      component="footer"
      sx={{
        py: 5,
        px: 2,
        mt: "40px",
        background: "transparent",
        borderTop: "1px solid gray",
      }}
    >
      <Container>
        <Typography variant="body1" align="center">
          All right reserved!
        </Typography>
        <Typography variant="body2" color="inherit" align="center">
          {"Copyright © "}
          <Link color="inherit" href="/https://localseo.lk/">
            LocalSEO
          </Link>{" "}
          {new Date().getFullYear()}
          {"."}
        </Typography>
      </Container>
    </Box>
  );
};

export default Footer;
