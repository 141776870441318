import { Route, Routes, useLocation } from "react-router-dom";
import "./App.css";
import Sidebar from "./components/Sidebar";
import LoginRegister from "./pages/login/LoginRegister";
import Footer from "./components/Footer";

function App() {
  const location = useLocation();

  return (
    <div className="App">
      {location.pathname !== "/loginsignup" && <Sidebar />}
      <Routes>
        <Route path="/loginsignup" element={<LoginRegister />} />
      </Routes>
      {location.pathname !== "/loginsignup" && <Footer />}
    </div>
  );
}

export default App;
