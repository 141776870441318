import React, { createContext, useState, useEffect, useContext } from "react";
import { getAllItems, getAllProducts } from "../utills/utills.js";
const ItemContext = createContext();

export const ItemContextProvider = ({ children }) => {
  
  const [allItems, setAllItems] = useState([]);
  const [allProducts, setAllProducts] = useState([]);

  //   get all items
  useEffect(() => {
    const fetchItems = async () => {
      try {
        const response = await getAllItems();
        // console.log(response);
        setAllItems(response);
      } catch (error) {
        console.error("Error fetching items:", error);
      }
    };
    fetchItems();
  }, []);

  //   get all products
  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await getAllProducts();
        setAllProducts(response);
      } catch (error) {
        console.log("Error fetching products", error);
      }
    };
    fetchProducts();
  }, []);
  //   console.log(allProducts);






  return (
    <ItemContext.Provider
      value={{
        allItems,
        allProducts,
      }}
    >
      {children}
    </ItemContext.Provider>
  );
};
export const useItemContext = () => useContext(ItemContext);
