// src/components/Cart/CartItem.js

import React from "react";
import { styled } from "@mui/system";
import {
  Button,
  Card,
  CardContent,
  CardMedia,
  Typography,
} from "@mui/material";

const StyledCard = styled(Card)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  margin: "20px 0",
  padding: "16px",
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[3],
  [theme.breakpoints.up("md")]: {
    flexDirection: "row",
    padding: theme.spacing(2),
  },
}));

const StyledMedia = styled(CardMedia)(({ theme }) => ({
  width: "100%",
  height: 0,
  paddingTop: "56.25%", // 16:9 aspect ratio
  borderRadius: 8,
  [theme.breakpoints.up("md")]: {
    width: 200,
    height: "auto",
    paddingTop: 0,
  },
}));

const StyledContent = styled(CardContent)(({ theme }) => ({
  flexGrow: 1,
  width: "100%",
  marginTop: 16,
  [theme.breakpoints.up("md")]: {
    marginTop: 0,
    marginLeft: "16px",
  },
}));

const StyledButton = styled(Button)(({ theme }) => ({
  marginTop: "16px",
  width: "100%",
  [theme.breakpoints.up("md")]: {
    marginTop: 0,
    width: "auto",
  },
}));

const CartItem = ({ product, onRemove }) => {
  return (
    <StyledCard>
      <StyledMedia
        component="img"
        image={product.product_image}
        alt={product.product_name}
      />
      <StyledContent>
        <Typography variant="h6" component="h2" gutterBottom>
          {product.product_name}
        </Typography>
        <Typography variant="body2" color="textSecondary" gutterBottom>
          {product.description}
        </Typography>
        <Typography variant="body1" color="primary" gutterBottom>
          Price: ${product.price}
        </Typography>
      </StyledContent>
      <StyledButton variant="contained" color="secondary" onClick={onRemove}>
        Remove
      </StyledButton>
    </StyledCard>
  );
};

export default CartItem;
