import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import Grid from "@mui/material/Grid";
import { useAuth } from "../../context/UserContext";
import IconButton from "@mui/material/IconButton";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { useCartContext } from "../../context/cartContext";
import { useItemContext } from "../../context/ItemsContext";
import { useSnackbar } from "notistack";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import { Link } from "react-router-dom";

const UserDashboard = () => {
  const [myItems, setMyItems] = useState([]);
  const { user } = useAuth();
  const { allProducts, allItems } = useItemContext();
  const { orders } = useCartContext();
  const [filteredItems, setFilteredItems] = useState([]);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (user) {
      const myPurchases = orders.filter(
        (order) =>
          order.status === "accepted" &&
          new Date(order.expire_date) > new Date() &&
          order.user_id === user.user_id
      );

      const productIds = myPurchases.map((purchase) => purchase.product_id);

      const myItems = allProducts
        .filter((product) => productIds.includes(product.product_id))
        .map((product) => {
          const order = myPurchases.find(
            (purchase) => purchase.product_id === product.product_id
          );
          return {
            ...product,
            start_date: order.purchase_date,
            finish_date: order.expire_date,
          };
        });

      setMyItems(myItems);
      setFilteredItems(myItems);
    }
  }, [orders, user, allProducts]);

  const options = myItems.map((item) => item.product_name);

  const handleSearchChange = (event, value) => {
    if (value) {
      const newItems = myItems.filter((item) =>
        item.product_name.toLowerCase().includes(value.toLowerCase())
      );
      setFilteredItems(newItems);
    } else {
      setFilteredItems(myItems);
    }
  };

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
    enqueueSnackbar("Copied to clipboard", {
      variant: "success",
      icon: <CheckCircleIcon />,
    });
  };

  const formatDate = (date) => {
    return new Date(date).toLocaleDateString();
  };

  const recommendedItems = allItems.slice(0, 4);

  return (
    <Container maxWidth="lg">
      <Box py={4}>
        <div className="w-full flex items-center justify-between text-2xl font-semibold mb-6">
          <h1 className="text-4xl font-semibold">Dashboard</h1>
        </div>
        {user && (
          <Typography variant="h4" component="h2" color="primary" gutterBottom>
            Hello, {user.username}
          </Typography>
        )}
        <Typography variant="body1" paragraph>
          Welcome to your dashboard! Here you can manage your purchases and keep
          track of your items.
        </Typography>
      </Box>
      <Box mb={4}>
        <Autocomplete
          freeSolo
          disableClearable
          options={options}
          onInputChange={handleSearchChange}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Search items"
              variant="outlined"
              fullWidth
            />
          )}
        />
      </Box>
      <Grid container spacing={4}>
        {filteredItems.length > 0 ? (
          filteredItems.map((item) => (
            <Grid
              sx={{ height: 250, cursor: "pointer" }}
              item
              xs={12}
              sm={6}
              md={4}
              lg={3}
              key={item.product_id}
            >
              <Card
                sx={{
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                }}
                className="relative bg-slate-100 p-4 rounded-lg overflow-hidden shadow-lg transform transition-transform duration-300 hover:scale-105"
              >
                <CardMedia
                  component="img"
                  sx={{ height: "100%" }}
                  image={item.product_image}
                  alt={item.product_name}
                />
                <CardContent
                  sx={{ flexGrow: 1 }}
                  className="absolute inset-0 flex flex-col items-center justify-center bg-white bg-opacity-75 opacity-0 hover:opacity-100 transition-opacity duration-300"
                >
                  <Typography
                    sx={{ fontWeight: "bold" }}
                    variant="h6"
                    component="div"
                  >
                    {item.product_name}
                  </Typography>
                  <Typography sx={{ fontWeight: "bold" }} variant="body2">
                    Start Date: {formatDate(item.start_date)}
                  </Typography>
                  <Typography sx={{ fontWeight: "bold" }} variant="body2">
                    Finish Date: {formatDate(item.finish_date)}
                  </Typography>
                  <Tooltip>
                    <Typography
                      sx={{ fontWeight: "bold" }}
                      variant="body2"
                      className="cursor-pointer text-rose-600 underline"
                      onClick={() => copyToClipboard(item.item_email)}
                    >
                      Email: {item.item_email.replace(/.(?=.{3})/g, "*")}
                      <IconButton>
                        <ContentCopyIcon sx={{ fontSize: 18 }} />
                      </IconButton>
                    </Typography>
                  </Tooltip>
                  <Tooltip>
                    <Typography
                      sx={{ fontWeight: "bold" }}
                      variant="body2"
                      className="cursor-pointer text-rose-600 underline"
                      onClick={() => copyToClipboard(item.item_password)}
                    >
                      Password: {"******"}
                      <IconButton>
                        <ContentCopyIcon sx={{ fontSize: 18 }} />
                      </IconButton>
                    </Typography>
                  </Tooltip>
                  <Tooltip>
                    <Typography
                      sx={{ fontWeight: "bold" }}
                      variant="body2"
                      className="cursor-pointer text-rose-600 underline"
                    >
                      <a
                        href={
                          item.item_url.startsWith("http://") ||
                          item.item_url.startsWith("https://")
                            ? item.item_url
                            : `http://${item.item_url}`
                        }
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {item.item_url}
                      </a>
                    </Typography>
                  </Tooltip>
                </CardContent>
              </Card>
            </Grid>
          ))
        ) : (
          <Grid item xs={12}>
            <Typography
              variant="h6"
              align="center"
              color="textSecondary"
              paragraph
            >
              You have no items in your list. Here are some recommendations for
              you:
            </Typography>
            <Grid container spacing={3}>
              {recommendedItems.map((item) => (
                <Grid item xs={12} sm={6} md={4} lg={3} key={item.item_id}>
                  <Link to={`/shopping/${item.item_id}`}>
                    <Card
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        height: "100%",
                        borderRadius: 2,
                        boxShadow: 3,
                        transition: "transform 0.3s ease, box-shadow 0.3s ease",
                        "&:hover": {
                          transform: "scale(1.05)",
                          boxShadow: 6,
                        },
                      }}
                    >
                      <CardMedia
                        component="img"
                        image={item.product_image}
                        alt={item.product_name}
                        sx={{ height: 200, objectFit: "cover" }}
                      />
                    </Card>
                  </Link>
                </Grid>
              ))}
            </Grid>
          </Grid>
        )}
      </Grid>
    </Container>
  );
};

export default UserDashboard;
