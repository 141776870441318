import React, { useState } from "react";
import { useNavigate, useParams, Link } from "react-router-dom";
import {
  Typography,
  Container,
  Button,
  TextField,
  FormControl,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  Slider,
} from "@mui/material";
import { styled } from "@mui/system";
import dayjs from "dayjs";
import { getProductsByItemId, addProductToCart } from "../../utills/utills";
import { useAuth } from "../../context/UserContext";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import { useSnackbar } from "notistack";
import { useItemContext } from "../../context/ItemsContext";

const StyledButton = styled(Button)({
  width: "100%",
  backgroundColor: "blueviolet",
  "&:hover": {
    backgroundColor: "blue",
  },
});

const StyledSlider = styled(Slider)({
  width: "100%",
  color: "blueviolet",
  "& .MuiSlider-thumb": {
    "&:hover": {
      boxShadow: "0px 0px 0px 8px rgba(0, 0, 255, 0.16)",
    },
    "&.Mui-focusVisible": {
      boxShadow: "0px 0px 0px 8px rgba(0, 0, 255, 0.16)",
    },
  },
});

const Product = () => {
  const { id } = useParams();
  const { allItems } = useItemContext();
  const { user } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const [duration, setDuration] = useState(1);
  const startDate = dayjs().format("YYYY-MM-DD");
  const [finishDate, setFinishDate] = useState(
    dayjs().add(1, "month").format("YYYY-MM-DD")
  );

  if (!allItems || allItems.length === 0) {
    return (
      <Typography variant="h6" className="mt-8">
        Loading...
      </Typography>
    );
  }

  const product = allItems.find((p) => p.item_id === parseInt(id));

  if (!product) {
    return (
      <Typography variant="h6" className="mt-8">
        Product not found
      </Typography>
    );
  }

  const maxDuration = dayjs(product.finish_date).diff(dayjs(), "month") + 1;

  const handleSliderChange = (event, newValue) => {
    setDuration(newValue);
    setFinishDate(dayjs(startDate).add(newValue, "month").format("YYYY-MM-DD"));
  };

  const handleInputChange = (event) => {
    const value = event.target.value === "" ? "" : Number(event.target.value);
    if (value >= 1 && value <= maxDuration) {
      setDuration(value);
      setFinishDate(dayjs(startDate).add(value, "month").format("YYYY-MM-DD"));
    }
  };

  const calculatePrice = () => {
    return product.price * duration || product.price;
  };

  const handleAddToCart = async () => {
    const price = calculatePrice();
    if (!user) {
      enqueueSnackbar("Please login to add to cart", { variant: "warning" });
      navigate("/loginsignup");
    }
    try {
      const availableProducts = await getProductsByItemId(product.item_id);
      if (availableProducts.length === 0) {
        enqueueSnackbar("No available products for this item.", {
          variant: "error",
        });
        return;
      }
      const selectedProductId = availableProducts[0].product_id;

      const response = await addProductToCart(
        user.user_id,
        selectedProductId,
        startDate,
        finishDate,
        price
      );
      console.log(`Added to cart: ${response.message}`);
      enqueueSnackbar("Added to cart", { variant: "success" });
      window.location.reload();
    } catch (error) {
      console.error("Error adding product to cart:", error.message);
      enqueueSnackbar("Failed to add to cart", { variant: "error" });
    }
  };

  // Filter similar products (for simplicity, we'll assume similar products have the same item_type)
  const similarProducts = allItems
    .filter(
      (p) => p.item_type === product.item_type && p.item_id !== product.item_id
    )
    .slice(0, 4); // Limit to 4 similar products

  return (
    <Container maxWidth="lg" sx={{ py: 6 }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", lg: "row" },
          justifyContent: "space-between",
          alignItems: "center",
          mb: 4,
        }}
      >
        <Typography
          variant="h4"
          sx={{ textAlign: { xs: "center", lg: "left" } }}
        >
          {product.item_name}
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", lg: "row" },
          gap: 6,
        }}
      >
        <Box sx={{ flex: { xs: "100%", lg: "50%" } }}>
          <img
            src={product.product_image}
            alt={product.item_name}
            style={{
              width: "100%",
              height: "auto",
              borderRadius: "8px",
              boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
              cursor: "pointer",
            }}
          />
        </Box>
        <Box
          sx={{
            flex: { xs: "100%", lg: "50%" },
            bgcolor: "white",
            borderRadius: "8px",
            p: 4,
            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
          }}
        >
          <Typography variant="h5" sx={{ color: "blueviolet", mb: 2 }}>
            {product.item_name}
          </Typography>
          <Typography variant="body2" sx={{ color: "red", mb: 2 }}>
            Availability: {product.available_products} items
          </Typography>
          <Typography variant="body1" sx={{ fontWeight: "bold", mb: 1 }}>
            {product.description}
          </Typography>
          <Typography variant="body1" sx={{ fontWeight: "bold", mb: 1 }}>
            {product.item_type}
          </Typography>
          <Typography variant="body1" sx={{ fontWeight: "bold", mb: 1 }}>
            Expire Date: {dayjs(product.finish_date).format("DD/MM/YYYY")}
          </Typography>
          <TableContainer component={Paper} sx={{ mt: 4 }}>
            <Table aria-label="price table">
              <TableHead>
                <TableRow>
                  <TableCell>Duration</TableCell>
                  <TableCell align="right">Price (USD)</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>30 days</TableCell>
                  <TableCell align="right">${product.price}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          my: 5,
          px: 3,
        }}
      >
        <StyledSlider
          value={duration}
          onChange={handleSliderChange}
          min={1}
          max={maxDuration}
          valueLabelDisplay="auto"
          sx={{ width: { xs: "100%", lg: "100%" } }}
        />
        <FormControl sx={{ my: 3, width: 100 }}>
          <TextField
            label="Months"
            value={duration}
            onChange={handleInputChange}
            type="number"
            inputProps={{ min: 1, max: maxDuration }}
            sx={{ textAlign: "center" }}
          />
        </FormControl>
        <StyledButton
          variant="contained"
          onClick={handleAddToCart}
          disabled={product.available_products <= 0}
          sx={{ width: { xs: "100%", lg: "30%" }, paddingY: 2 }}
        >
          <ShoppingCartIcon sx={{ mr: 1 }} />
          Add to Cart
        </StyledButton>
      </Box>
      <Box
        sx={{
          mt: 6,
          mb: 3,
          p: 4,
          bgcolor: "gray.100",
          borderRadius: "8px",
          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
          width: "100%",
          textAlign: "center",
        }}
      >
        <Typography variant="h6" sx={{ mb: 2 }}>
          Price Summary
        </Typography>
        <Typography variant="body1">
          {duration} month(s) : ${calculatePrice()}
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", sm: "row" },
          alignItems: "center",
          justifyContent: "space-between",
          mb: 6,
        }}
      >
        <Typography variant="body2" sx={{ mt: 2, textAlign: "center" }}>
          <strong>Start Date:</strong> {startDate}
        </Typography>
        <Typography variant="body2" sx={{ mt: 2, textAlign: "center" }}>
          <strong>Finish Date:</strong> {finishDate}
        </Typography>
      </Box>
      <Typography
        variant="h4"
        sx={{ mt: 10, mb: 4, textAlign: { xs: "center", lg: "left" } }}
      >
        Similar Products
      </Typography>
      {similarProducts.length > 0 && (
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: {
              xs: "repeat(2, 1fr)",
              sm: "repeat(3, 1fr)",
              lg: "repeat(4, 1fr)",
            },
            gap: 4,
            mt: 8,
          }}
        >
          {similarProducts.map((similarProduct) => (
            <Link
              key={similarProduct.item_id}
              to={`/shopping/${similarProduct.item_id}`}
              style={{ textDecoration: "none" }}
            >
              <Box
                sx={{
                  position: "relative",
                  bgcolor: "white",
                  borderRadius: "8px",
                  boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
                  overflow: "hidden",
                  cursor: "pointer",
                  transition: "transform 0.3s",
                  "&:hover": { transform: "scale(1.05)" },
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  height: 240,
                }}
              >
                {similarProduct.available_products <= 0 && (
                  <Box
                    sx={{
                      position: "absolute",
                      top: 10,
                      right: -25,
                      bgcolor: "red",
                      color: "white",
                      px: 2,
                      py: 1,
                      fontSize: "12px",
                      fontWeight: "bold",
                      transform:
                        "rotate(45deg) translateX(4px) translateY(-4px)",
                    }}
                  >
                    Out of Stock
                  </Box>
                )}
                <img
                  src={similarProduct.product_image}
                  alt={similarProduct.item_name}
                  style={{ height: "75%", width: "100%", objectFit: "cover" }}
                />
                <Box sx={{ textAlign: "center", p: 2 }}>
                  <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                    {similarProduct.item_name}
                  </Typography>
                </Box>
              </Box>
            </Link>
          ))}
        </Box>
      )}
    </Container>
  );
};

export default Product;
